const baseApiUrl = process.env.VUE_APP_MAIN_DOMAIN_URL;
const autoCompleteApiUrl = process.env.VUE_APP_AUTOCOMPLETE_URL;

export const LOGIN = async (data) => {
  return fetch(`${baseApiUrl}/login`, {
    method: "POST",
    cache: "no-cache",
    body: data,
  });
};

export const REGISTER = async (data) => {
  return fetch(`${baseApiUrl}/users`, {
    method: "POST",
    cache: "no-cache",
    body: data,
  });
};

export const RECOVERY_PASSWORD = async (data, myHeaders) => {
  return fetch(`${baseApiUrl}/recoverypass`, {
    method: "POST",
    headers: myHeaders,
    cache: "no-cache",
    redirect: "follow",
    body: data,
  });
};

export const LOG_LOGOUTS = async (data) => {
  return fetch(`${baseApiUrl}/logger`, {
    method: "POST",
    cache: "no-cache",
    body: data,
  });
};

export const LOGIN_MOBILE = async (phone) => {
  return fetch(`${baseApiUrl}/loginReceiveCode`, {
    method: "POST",
    cache: "no-cache",
    body: phone,
  });
};

export const LOGIN_CODE = async (data) => {
  return fetch(`${baseApiUrl}/loginSendCode`, {
    method: "POST",
    cache: "no-cache",
    body: data,
  });
};

export const GET_USERS = async (userId) => {
  return fetch(`${baseApiUrl}/users/${userId}`, {
    method: "GET",
    cache: "no-cache",
  });
};

export const SET_REGISTER_EDU = async (data) => {
  return fetch(`${baseApiUrl}/edu/`, {
    method: "POST",
    cache: "no-cache",
    body: data,
  });
};

export const GET_USER_TECH = async (params) => {
  return fetch(`${baseApiUrl}/user_tech/${params}`, {
    method: "GET",
    cache: "no-cache",
  });
};

export const SET_SERVICE_MSG = async (params, isParams = false) => {
  if (!isParams) {
    return fetch(`${baseApiUrl}/service_msg/`, params);
  }
  return fetch(`${baseApiUrl}/service_msg/`, {
    method: "POST",
    cache: "no-cache",
    body: params,
  });
};

export const SET_SERVICE_ORDERS = async (params) => {
  return fetch(`${baseApiUrl}/service_orders/`, params);
};

export const CHANGE_PASSWORD = async (params) => {
  return fetch(`${baseApiUrl}/changepass`, params);
};

export const SET_USERS = async (id, params) => {
  return fetch(`${baseApiUrl}/users/${id}`, params);
};

export const GET_PRODUCTS = async (params) => {
  return fetch(`${baseApiUrl}/products/${params}`, {
    method: "GET",
    cache: "no-cache",
  });
};

export const SEARCH_PRODUCTS = async (params) => {
  return fetch(`${baseApiUrl}/search&q=${params}`, {
    method: "GET",
    cache: "no-cache",
  });
};

export const PRODUCT_ORDERS = async (params) => {
  return fetch(`${baseApiUrl}/product_orders/`, params);
};

export const JSON_CATEGORIES = async (params) => {
  return fetch(`${baseApiUrl}/categories/${params}`, {
    method: "GET",
    cache: "no-cache",
  });
};

export const SQUARE_PARTS = async (data) => {
  return fetch(`${baseApiUrl}/spare_parts/`, {
    method: "POST",
    cache: "no-cache",
    redirect: "follow",
    body: data,
  });
};

export const GET_REGIONS = async () => {
  return fetch(`${baseApiUrl}/getRegions`, {
    method: "GET",
    cache: "no-cache",
  });
};

export const GET_DISTRICTS = async (regionId) => {
  return fetch(`${baseApiUrl}/getDistricts/${regionId}`, {
    method: "GET",
    cache: "no-cache",
  });
};

export const GET_SERVICE_CENTRES = async () => {
  return fetch(`${baseApiUrl}/getServices/ua`, {
    method: "GET",
    cache: "no-cache",
  });
};

export const GET_HOLDINGS = async (userId) => {
  return fetch(`${baseApiUrl}/children/ua/${userId}`, {
    method: "GET",
    cache: "no-cache",
  });
};

export const GET_AUTOCOMPLETE_BRANDS = async (text, userId) => {
  return fetch(
    `${autoCompleteApiUrl}/application_create/autocompleteBrand&filter_brand=${
      text || ""
    }&filter_customer_id=${userId} `,
    {
      method: "GET",
      cache: "no-cache",
    }
  );
};

export const GET_AUTOCOMPLETE_TYPES = async (text, brand, userId) => {
  return fetch(
    `${autoCompleteApiUrl}/application_create/autocompleteName&filter_brand=${brand}&filter_name=${
      text || ""
    }&filter_customer_id=${userId}  `,
    {
      method: "GET",
      cache: "no-cache",
    }
  );
};

export const GET_AUTOCOMPLETE_MODELS = async (text, brand, type, userId) => {
  return fetch(
    `${autoCompleteApiUrl}/application_create/autocompleteModel&filter_brand=${brand}&filter_name=${type}&filter_model=${
      text || ""
    }&filter_customer_id=${userId} `,
    {
      method: "GET",
      cache: "no-cache",
    }
  );
};

export const GET_AUTOCOMPLETE_SERIALS = async (
  text,
  brand,
  type,
  model,
  userId
) => {
  return fetch(
    `${autoCompleteApiUrl}/application_create/autocompleteSerial&filter_brand=${brand}&filter_name=${type}&filter_model=${model}&filter_serial=${
      text || ""
    }&filter_customer_id=${userId}`,
    {
      method: "GET",
      cache: "no-cache",
    }
  );
};
