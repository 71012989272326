<template>
  <div>
    <main>
      <router-view />
    </main>
  </div>
</template>

<style scoped>
/*ul {*/
/*  list-style: none;*/
/*  display: flex;*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*}*/
/*li {*/
/*  padding: 5px 15px 5px 0;*/
/*}*/
/*li a {*/
/*  text-decoration: none;*/
/*  color: black;*/
/*}*/
/*li a:hover {*/
/*  color: #404040;*/
/*}*/
</style>

<script>
// import {
//   mapGetters
// } from 'vuex'
export default {
  // computed: {
  //   allGets (state) {
  //     return this.$store.getters.allGetCategory
  //   }
  // }
  // computed: mapGetters(['allGetCategory']),
  methods: {},
  mounted() {
    // this.fetchPosts()
    localStorage.setItem("lang", "Українська");
    localStorage.setItem("activeItem", "1");
    this.$ml.change("ua");
  },
  data: () => ({
    links: [
      {
        name: "Home",
        to: "/"
      },
      {
        name: "Service Requests",
        to: "/ServiceRequests"
      },
      {
        name: "New Request",
        to: "/NewRequest"
      },
      {
        name: "New Request Equipment",
        to: "/NewRequestEquipment"
      },
      {
        name: "Modal",
        to: "/modal"
      },
      {
        name: "404",
        to: "/random-bad-url"
      },
      {
        name: "Catalog",
        to: "/Catalog"
      }
    ]
  })
};
if (Number(localStorage.getItem("size")) === 0) {
  localStorage.setItem("size", 1);
}
document.body.setAttribute(
  "style",
  "font-size:" + Number(localStorage.getItem("size")) + "rem !important"
);
</script>
