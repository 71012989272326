<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/TrainingRequestsList" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{title}}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="request-holder">
        <div class="request-info">
          <div class="request-row">
            <span class="request-name request-name-status">
              <span class="request-name">{{$ml.with('VueJS').get('city')}}</span>
              <span class="request-status status-canceled">{{resultItem.statuss}}</span>
            </span>
            <span class="request-val">
              <span>{{resultItem.region}}</span>
            </span>
          </div>
          <div class="request-row">
            <span class="request-name">{{$ml.with('VueJS').get('topic')}}</span>
            <span class="request-val">{{resultItem.theme}}</span>
          </div>
<!--          <div class="request-row">-->
<!--            <span class="request-name">Желаемая дата</span>-->
<!--            <span class="request-val">{{resultItem.date_start}} по {{resultItem.date_end}}</span>-->
<!--          </div>-->
          <div class="request-row" v-if="resultItem.date_start == resultItem.date_end">
            <span class="request-name">{{$ml.with('VueJS').get('desiredDate')}}</span>
            <span class="request-val">{{resultItem.date_start}}</span>
          </div>
          <div class="request-row" v-else>
            <span class="request-name">{{$ml.with('VueJS').get('desiredDates')}}</span>
            <span class="request-val">с {{resultItem.date_start}} по {{resultItem.date_end}}</span>
          </div>
          <div v-if="resultItem.member_count !== '0'" class="request-row">
            <span class="request-name">{{$ml.with('VueJS').get('numberOfPersons')}}</span>
            <span class="request-val">{{resultItem.member_count}}</span>
          </div>
          <div v-if="resultItem.description" class="request-row">
            <span class="request-name">{{$ml.with('VueJS').get('сomment')}}</span>
            <span class="request-val">{{resultItem.description}}</span>
          </div>
          <div class="request-row">
            <span class="request-name">{{$ml.with('VueJS').get('lecturer')}}</span>
            <span class="request-val item-flex-holder">
              <span class="item-img">
                <img v-bind:src="teachers.photo" v-bind:alt="teachers.name">
              </span>
              <span class="item-name">{{teachers.name}}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      title: 'Заявка № ' + this.$route.params['id'],
      requests: '',
      id: this.$route.params['id'],
      res: [],
      teachers: ''
    }
  },
  watch: {
    $route (to, from) {
      this.id = to.params['id']
    }
  },
  mounted () {
    this.loadJsonEdu()
    this.loadJsonLectors()
  },
  computed: {
    resultItem: function () {
      for (let asd in this.requests) {
        console.log(this.id)
        if (this.requests[asd].education_id === this.id) {
          return this.requests[asd]
        }
      }
      return 'false'
    }
  },
  methods: {
    async loadJsonEdu () {
      await this.eduJson.get().then(response => response.json())
        .then(response => {
          for (var item in response) {
            response[item].statuss = 'В роботі'
          }
          this.requests = response
          console.log(this.requests)
        })
    },
    async loadJsonLectors () {
      await this.lectorsJson.get().then(response => response.json())
        .then(response => {
          this.teachers = response
        })
    }
  },
  created () {
    this.eduJson = this.$resource('edu/' + localStorage.userId)
    this.lectorsJson = this.$resource('lectors/' + 7)
  }
}
</script>
