<template>
  <div class="section-top">
    <div class="title-holder">
      <a v-bind:href="url" class="link-back">
        <i class="icon icon-arrow-back"></i>
      </a>
      <h1 class="title" v-if="title">
        <span class="title-item">{{title}}</span>
      </h1>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
    url: String
  },
  data () {
    return {
    }
  }
}
</script>
