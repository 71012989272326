<template>
  <div>
    <modal
      name="send-request-equipment"
      :adaptive="true"
      :resizable="true"
      height="auto"
      id="send-request-equipment"
    >
      <h3>{{ $ml.with("VueJS").get("sendRequestQ") }}</h3>
      <div class="modal-buttons">
        <!--        <button-->
        <!--          @click="$modal.show('success-request-equipment'), $modal.hide('send-request-equipment'), sendApiForm()"-->
        <!--          class="btn btn-main"-->
        <!--        >Отправить-->
        <!--        </button>-->
        <a v-bind:href="url" class="btn btn-main"
          >{{ $ml.with("VueJS").get("send") }}
        </a>
        <button
          @click="$modal.hide('send-request-equipment')"
          class="btn btn-link"
        >
          {{ $ml.with("VueJS").get("cancel") }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
// import ModalSuccessForm from '../modal/ModalSuccessForm'
import { PRODUCT_ORDERS } from "../../helpers/requests";

export default {
  props: {
    url: String,
  },
  components: {
    // ModalSuccess: ModalSuccessForm
  },
  methods: {
    async sendApiForm() {
      const objProductOrder = {
        name: localStorage.getItem("name"),
        email: localStorage.getItem("userEmail"),
        phone: localStorage.getItem("userPhone"),
        user_id: 320,
        product_id: this.$route.params["idProduct"],
        count: 22,
      };
      console.log(objProductOrder);
      var myHeaders = new Headers();
      // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
      myHeaders.append("Access-Control-Allow-Origin", "*");

      var urlencoded = new URLSearchParams();
      urlencoded.append("name", "Василий Иванов");
      urlencoded.append("e-mail", "test@test.com");
      urlencoded.append("phone", "+380969379992");
      urlencoded.append("user_id", "320");
      urlencoded.append("product_id", "56");
      urlencoded.append("count", "22");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      await PRODUCT_ORDERS(requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    // this.$http.post('product_orders/', objProductOrder)
    //   .then(resource => {
    //     return resource.body()
    //   })
    // this.resourcess = this.$resource('product_orders/')
    // this.resourcess.save({}, objProductOrder)
    //   .then(response => {
    //     console.log(response)
    //     console.log('POST send good!!!)')
    //   })
  },
};
</script>
