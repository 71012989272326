<template>
  <div class="page-container">
    <div class="section-top section-top-lg">
      <div class="title-holder">
        <a v-bind:href="'#/Catalog/' + urlParentId()" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <Loader v-if="loading"/>
        <h1 v-else class="title">
          <span class="title-item">{{titleNameCat}}</span>
        </h1>
      </div>
      <div class="sort-holder title-filter-list" v-if="this.id == 59">
          <ul class="sort-list">
              <li class="sort-list-item" v-bind:class="{active: disabled === 0}">
                  <span class="sort-list-item-link" v-on:click="disabled=0">Все</span>
              </li>
              <li class="sort-list-item" v-bind:class="{active: disabled === 225}">
                <span class="sort-list-item-link" v-on:click="disabled=225">90-225 л.с.</span>
              </li>
              <li class="sort-list-item" v-bind:class="{active: disabled === 400}">
                  <span class="sort-list-item-link" v-on:click="disabled=400">226-400 л.с.</span>
              </li>
              <li class="sort-list-item" v-bind:class="{active: disabled === 600}">
                  <span class="sort-list-item-link" v-on:click="disabled=600">401-600 л.с.</span>
              </li>
          </ul>
      </div>
    </div>
    <Loader v-if="loading"/>
    <div v-else class="section-main catalog-main-section">
      <div class="sub-catalog-list">
        <div class="sub-catalog-item" v-for="(product) in productsFilter" :key="product.id">
          <div v-if="product.category_id === id">
            <div class="sub-catalog-item__img">
              <a v-bind:href="'#/CatalogDetail/' + id + '/' + product.id">
                <img style="margin: -20% 0px;" v-bind:src="product.preview_img" v-bind:alt="product.name">
              </a>
            </div>
            <div class="sub-catalog-item__info">
              <div class="sub-catalog-item__name">
                <a v-bind:href="'#/CatalogDetail/' + id + '/' + product.id">{{product.name}}</a>
              </div>
              <div class="info-list" v-for="(property, index) in product.property2" :key="property.id">
                <div v-if="index <= 1">
                  <div class="info-list__row" v-for="(prop, indexs) in property" :key="prop.id">
                    <div class="info-list__title">{{indexs}}:</div>
                    <div class="info-list__value" v-for="(value) in prop" :key="value.id">{{value}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
module.exports = {
  data () {
    return {
      noFilter: true,
      isActive: true,
      disabled: 0,
      category: Object,
      products: Object,
      titleName: '',
      loading: true,
      errored: false,
      id: this.$route.params['id']
    }
  },
  computed: {
    titleNameCat: function () {
      this.titleName = this.category.name
      return this.titleName
    },
    productsFilter: function () {
      // let products = this.products
      var resultat = []
      // var resultat = this.products
      let productRange = [
        { 225: [
          { productId: '56', power: 110 },
          { productId: '57', power: 112 },
          { productId: '58', power: 126 },
          { productId: '59', power: 141 },
          { productId: '60', power: 126 },
          { productId: '61', power: 158 },
          { productId: '62', power: 182 },
          { productId: '63', power: 213 },
          { productId: '64', power: 225 }
        ] },
        { 400: [
          { productId: '65', power: 271 },
          { productId: '66', power: 300 },
          { productId: '67', power: 250 },
          { productId: '68', power: 280 },
          { productId: '69', power: 311 },
          { productId: '70', power: 340 },
          { productId: '71', power: 379 }
        ] },
        { 600: [
          { productId: '72', power: 406 },
          { productId: '73', power: 457 },
          { productId: '74', power: 508 },
          { productId: '75', power: 557 },
          { productId: '76', power: 608 },
          { productId: '77', power: 457 },
          { productId: '78', power: 508 },
          { productId: '79', power: 613 },
          { productId: '80', power: 608 }
        ] }
      ]
      let productDisabled = this.disabled
      this.products.forEach(function (item) {
        switch (productDisabled) {
          case 225:
            productRange[0][productDisabled].forEach(function (innerItem) {
              if (item.id === innerItem.productId) {
                resultat.push(item)
              }
            })
            break
          case 400:
            productRange[1][productDisabled].forEach(function (innerItem) {
              if (item.id === innerItem.productId) {
                resultat.push(item)
              }
            })
            break
          case 600:
            productRange[2][productDisabled].forEach(function (innerItem) {
              if (item.id === innerItem.productId) {
                resultat.push(item)
              }
            })
            break
          case 0:
            resultat.push(item)
            break
        }
      })
      return resultat
    }
  },
  methods: {
    loadJsonCategories () {
      this.categoriesJson.get().then(response => response.json())
        .then(response => {
          this.category = response
        })
    },
    async loadJsonProducts () {
      await this.productsJson.get().then(response => response.json())
        .then(response => {
          this.products = response
        })
      this.loading = false
    },
    urlParentId () {
      return this.category.parent
    }
  },
  mounted () {
    this.loadJsonCategories()
    this.loadJsonProducts()
  },
  created () {
    if (localStorage.getItem('activeItem') === '1') {
      this.categoriesJson = this.$resource('categories/ua/' + this.id + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
      this.productsJson = this.$resource('products/ua&category=' + this.id + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
    } else {
      this.categoriesJson = this.$resource('categories/' + this.id + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
      this.productsJson = this.$resource('products&category=' + this.id + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
    }
  }
}
</script>
