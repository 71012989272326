<template>
  <div>
    <modal name="modal-basket" :adaptive="true" height="auto">
      <h3 class="modal-title">{{$ml.with('VueJS').get('deleteModalTitle')}}</h3>
      <div class="btn-holder">
        <a href="#/BasketEmpty" @click=cleanCart() class="btn btn-main">{{$ml.with('VueJS').get('delete')}}</a>
      </div>
      <div class="btn-holder">
        <button @click="$modal.hide('modal-basket')" class="btn btn-link">{{$ml.with('VueJS').get('сancel')}}</button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    cleanCart () {
      localStorage.removeItem('itemsIdBasket')
      this.$router.push('#/BasketEmpty')
    }
  }
}
</script>
