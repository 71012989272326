<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/TrainingRegistration_1" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{$ml.with('VueJS').get('recording2')}}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="selecting-title">{{$ml.with('VueJS').get('selectTopic')}}</div>
      <div class="items-list-holder">
        <nav class="items-list">
          <span
            class="list-item"
            v-for="(item, index) in themes"
            :key="index"
            v-bind:class="{active: activeItem === index}"
            @click="onActiveItem(index)"
          >{{item.name}}</span>
        </nav>
      </div>
    </div>
    <div class="section-bottom">
      <a href="#/TrainingRegistration_3" @click="next" class="btn btn-full-width btn-main">{{$ml.with('VueJS').get('proceed')}}</a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      themes: [
        {
          name: 'Тракторы Farmall'
        },
        {
          name: 'Тракторы Maxxum'
        },
        {
          name: 'Тракторы Puma'
        },
        {
          name: 'Тракторы Optum'
        },
        {
          name: 'Тракторы Magnum'
        },
        {
          name: 'Тракторы Steiger / Quadtrac'
        },
        {
          name: 'Комбайны 140 серии'
        },
        {
          name: 'Комбайны 250 серии'
        },
        {
          name: 'Опрыскиватели Patriot'
        }
      ],
      activeItem: localStorage.getItem('TrainingRegistration_2_id') | ''
    }
  },
  methods: {
    next (event) {
      event.preventDefault()
      localStorage.setItem('TrainingRegistration_2_name', this.themes[this.activeItem].name)
      localStorage.setItem('TrainingRegistration_2_id', this.activeItem)
      this.$router.push('/TrainingRegistration_3')
    },
    onActiveItem: function (index) {
      this.activeItem = index
    }
  }
}
</script>
