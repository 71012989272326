<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/TrainingRegistration_3" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{$ml.with('VueJS').get('recording4')}}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="form-holder">
        <form @submit.prevent="submitRequestForm()" id="people_num">
          <div class="form-group" v-bind:class="{'form-group-error': $v.selectNumPeople.$error}">
            <v-select
              id="select_people"
              name="select_people"
              class="select"
              label="peopleNumber"
              :options="options"
              :searchable="false"
              :clearable="false"
              :components="{OpenIndicator}"
              @change="$v.select.$touch()"
              @blur="$v.selectNumPeople.$touch()"
              v-model="selectNumPeople"
              v-bind:class="{'has-value': $v.selectNumPeople.$model}"
            ></v-select>
            <label for="select_people" class="label">{{$ml.with('VueJS').get('numberOfPersons')}}</label>
            <div class="error" v-if="$v.selectNumPeople.$error">{{$ml.with('VueJS').get('selectNumberOfPersons')}}</div>
          </div>

          <div class="form-group">
            <textarea
              id="fault_description"
              name="text"
              class="textarea textarea-lg"
              v-model="faultDescription"
              @input="$v.faultDescription.$touch()"
              @blur="$v.faultDescription.$touch()"
              v-bind:class="{'has-value': $v.faultDescription.$model}"
            >{faultDescription}</textarea>
            <label for="fault_description" class="label">{{$ml.with('VueJS').get('commentOptional')}}</label>
          </div>

        </form>
      </div>
    </div>
    <div class="section-bottom">
      <button @click="next()" type="submit" class="btn btn-full-width btn-main" form="people_num">{{$ml.with('VueJS').get('proceed')}}</button>
    </div>
  </div>
</template>

<script>
import { required, minValue } from 'vuelidate/dist/validators.min'
import 'vue-select/dist/vue-select.css'

export default {
  data () {
    return {
      selectNumPeople: localStorage.getItem('trainingPeople') | null,
      faultDescription: localStorage.getItem('trainingComment'),
      submitStatus: null,
      OpenIndicator: {
        render: createElement => createElement('span')
      },
      options: [2, 5, 8, 12, 16
        // {
        //   peopleNumber: '2'
        // },
        // {
        //   peopleNumber: '5'
        // },
        // {
        //   peopleNumber: '8'
        // },
        // {
        //   peopleNumber: '12'
        // },
        // {
        //   peopleNumber: '16'
        // }
      ]
    }
  },
  validations: {
    selectNumPeople: {
      required,
      minValue: minValue(1)
    },
    faultDescription: {
      // required,
      // minLength: minLength(20)
    }
  },
  mounted () {
    // this.faultDescription = localStorage.getItem('trainingComment')
  },
  methods: {
    next () {
      // event.preventDefault()
      console.log(this.faultDescription)
      localStorage.setItem('trainingPeople', this.selectNumPeople)
      localStorage.setItem('trainingComment', this.faultDescription)
      // this.$router.push('/TrainingRegistration_5')
    },
    submitRequestForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.$router.push('/TrainingRegistration_5')
        this.$v.$reset()
        this.submitStatus = 'PENDING'
        setTimeout(() => {
          this.submitStatus = 'OK'
        }, 500)
      }
    }
  }
}
</script>
