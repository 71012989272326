import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Side from "../views/Side.vue";
// import Catalog from '../components/catalog/Catalog'
// catalog
import CatalogSection from "../components/catalog/CatalogSection";
import CatalogDetail from "../components/catalog/CatalogDetail";
import NewRequestEquipment from "../components/catalog/NewRequestEquipment";
// Teaching
import Teaching from "../components/teaching/Teaching";
import Registration1 from "../components/teaching/TrainingRegistration_1";
import Registration2 from "../components/teaching/TrainingRegistration_2";
import Registration3 from "../components/teaching/TrainingRegistration_3";
import Registration4 from "../components/teaching/TrainingRegistration_4";
import Registration5 from "../components/teaching/TrainingRegistration_5";
import SuccessTraining from "../components/modal/SuccessTraining";
import SelectingCity from "../components/teaching/SelectingCity";
import QR from "../components/teaching/qrCode";
import Teachers from "../components/teaching/Teachers";
import TeacherDetail from "../components/teaching/TeacherDetail";
import TrainingRequestsList from "../components/teaching/TrainingRequestsList";
import TrainingRequestDetail from "../components/teaching/TrainingRequestDetail";
// Search
import Search from "../components/search/Search";
import SearchNoResult from "../components/search/SearchNoResult";
import SearchItemResult from "../components/search/SearchItemResult";
import SearchItemsResult from "../components/search/SearchItemsResult";
import AvailabilityModal from "../components/search/AvailabilityModal";
import Analogue from "../components/search/Analogue";
// ServiceRequests
import ServiceRequests from "../components/request/ServiceRequests";
import NewRequest from "../components/request/NewRequest";
import SuccessSendForm from "../components/modal/SuccessSendForm";
import Request from "../components/request/Request";
import SuccessTicketForm from "../components/modal/SuccessTicketForm.vue";
// Basket
import Basket from "../components/basket/Basket";
import BasketEmpty from "../components/basket/BasketEmpty";
// Settings
import Settings from "../components/settings/Settings";
import SettingsFont from "../components/settings/SettingsFont";
import SettingsLang from "../components/settings/SettingsLang";
// Personal
import Personal from "../components/profile/Personal";
import PersonalForm from "../components/profile/PersonalForm";
import PersonalPassword from "../components/profile/PersonalPassword";
import Login from "../components/profile/Login";
import EmailLogin from "../components/profile/EmailLogin";
import Registration from "../components/profile/Registration";
import SuccessRegistration from "../components/profile/SuccessRegistration";
import PasswordRecovery from "../components/profile/PasswordRecovery";
import SuccessPassword from "../components/profile/SuccessPassword";
// Pages
import Kodeks from "../components/pages/Kodeks";
import Policies from "../components/pages/Policies";
import TermsOfUse from "../components/pages/TermsOfUse";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { auth: false },
    component: Home,
  },
  // catalog
  {
    path: "/Catalog",
    meta: { auth: false },
    name: "Machines Catalog",
    component: () => import("../components/catalog/Catalog"),
    children: [
      {
        meta: { auth: false },
        path: "/Catalog/:id",
        component: () => import("../components/catalog/Catalog"),
      },
    ],
  },
  {
    meta: { auth: false },
    path: "/CatalogSection",
    name: "Catalog Section",
    component: CatalogSection,
    children: [
      {
        path: "/CatalogSection/:id",
        component: CatalogSection,
      },
    ],
  },
  {
    meta: { auth: false },
    path: "/CatalogDetail/:idCategory/:idProduct",
    name: "Catalog Detail",
    component: CatalogDetail,
  },
  {
    meta: { auth: false },
    path: "/NewRequestEquipment/:idCategory/:idProduct",
    name: "New Request Equipment",
    component: NewRequestEquipment,
  },
  // Pages
  {
    meta: { auth: false },
    path: "/Kodeks",
    name: "Kodeks",
    component: Kodeks,
  },
  {
    meta: { auth: false },
    path: "/Policies",
    name: "Policies",
    component: Policies,
  },
  {
    meta: { auth: false },
    path: "/TermsOfUse",
    name: "TermsOfUse",
    component: TermsOfUse,
  },
  // Teaching
  {
    meta: { auth: true },
    path: "/Teaching",
    name: "Teaching",
    component: Teaching,
  },
  {
    meta: { auth: true },
    path: "/TrainingRegistration_1",
    name: "Training registration step #1",
    component: Registration1,
  },
  {
    meta: { auth: true },
    path: "/TrainingRegistration_2",
    name: "Training registration step #2",
    component: Registration2,
  },
  {
    meta: { auth: true },
    path: "/TrainingRegistration_3",
    name: "Training registration step #3",
    component: Registration3,
  },
  {
    meta: { auth: true },
    path: "/TrainingRegistration_4",
    name: "Training registration step #4",
    component: Registration4,
  },
  {
    meta: { auth: true },
    path: "/TrainingRegistration_5",
    name: "Training registration step #5",
    component: Registration5,
  },
  {
    meta: { auth: true },
    path: "/SuccessTraining",
    name: "Success Training",
    component: SuccessTraining,
  },
  {
    meta: { auth: true },
    path: "/SelectingCity",
    name: "Selecting City",
    component: SelectingCity,
  },
  {
    meta: { auth: true },
    path: "/qrCode",
    name: "QR code",
    component: QR,
  },
  {
    meta: { auth: true },
    path: "/Teachers",
    name: "Teachers list",
    component: Teachers,
  },
  {
    meta: { auth: true },
    path: "/TeacherDetail/:id",
    name: "Teacher detail",
    component: TeacherDetail,
  },
  {
    meta: { auth: true },
    path: "/TrainingRequestsList",
    name: "Training requests list",
    component: TrainingRequestsList,
  },
  {
    meta: { auth: true },
    path: "/TrainingRequestDetail/:id",
    name: "Training request detail",
    component: TrainingRequestDetail,
  },
  // search
  {
    meta: { auth: true },
    path: "/Search",
    name: "Search",
    component: Search,
  },
  {
    meta: { auth: true },
    path: "/SearchNoResult",
    name: "Search No Result",
    component: SearchNoResult,
  },
  {
    meta: { auth: true },
    path: "/SearchItemResult/:id",
    name: "Search Item Result",
    component: SearchItemResult,
  },
  {
    meta: { auth: true },
    path: "/SearchItemsResult",
    name: "Search Items Result",
    component: SearchItemsResult,
  },
  {
    meta: { auth: true },
    path: "/AvailabilityModal/:id",
    name: "Availability Modal",
    component: AvailabilityModal,
  },
  {
    meta: { auth: true },
    path: "/Analogue/:id",
    name: "Analogue",
    component: Analogue,
  },
  // ServiceRequests
  {
    meta: { auth: true },
    path: "/ServiceRequests",
    name: "Service Requests",
    component: ServiceRequests,
  },
  // SuccessTicketForm
  {
    meta: { auth: true },
    path: "/SuccessTicketForm",
    name: "Success Ticket Form",
    component: SuccessTicketForm,
  },
  {
    meta: { auth: true },
    path: "/NewRequest",
    name: "NewRequest",
    component: NewRequest,
  },
  {
    meta: { auth: true },
    path: "/SuccessSendForm",
    name: "Success Send Form",
    component: SuccessSendForm,
  },
  {
    meta: { auth: true },
    path: "/Request/:id",
    name: "Request",
    component: Request,
  },
  // Basket
  {
    meta: { auth: true },
    path: "/Basket",
    name: "Basket",
    component: Basket,
  },
  {
    meta: { auth: true },
    path: "/BasketEmpty",
    name: "Basket Empty",
    component: BasketEmpty,
  },
  // Settings
  {
    meta: { auth: true },
    path: "/Settings",
    name: "Settings",
    component: Settings,
  },
  {
    meta: { auth: true },
    path: "/SettingsFont",
    name: "Settings Font",
    component: SettingsFont,
  },
  {
    meta: { auth: true },
    path: "/SettingsLang",
    name: "Settings Lang",
    component: SettingsLang,
  },
  // Personal
  {
    meta: { auth: true },
    path: "/Personal",
    name: "Personal",
    component: Personal,
  },
  {
    meta: { auth: true },
    path: "/PersonalForm",
    name: "Personal Form",
    component: PersonalForm,
  },
  {
    meta: { auth: true },
    path: "/PersonalPassword",
    name: "Personal Password",
    component: PersonalPassword,
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/EmailLogin",
    name: "EmailLogin",
    component: EmailLogin,
  },
  {
    path: "/Registration",
    name: "Registration",
    component: Registration,
  },
  {
    path: "/SuccessRegistration",
    name: "SuccessRegistration",
    component: SuccessRegistration,
  },
  {
    path: "/PasswordRecovery",
    name: "PasswordRecovery",
    component: PasswordRecovery,
  },
  {
    path: "/SuccessPassword",
    name: "SuccessPassword",
    component: SuccessPassword,
  },
  // {
  //   path: 'CatalogSection/:id/',
  //   name: 'Catalog Section',
  //   component: CatalogSection
  // },
  {
    path: "/side",
    name: "side",
    component: Side,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // const currentUser = false
  // const login = to.matched.some(record => record.meta.auth)
  const login = to.meta.auth;
  // console.log('email: test@test.com \npassword: pass')
  // if (login && !currentUser) {
  if (login && !localStorage.userToken) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
