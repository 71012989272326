<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/Teaching" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{$ml.with('VueJS').get('lecturers')}}</span>
        </h1>
      </div>
    </div>
    <div class="section-main catalog-main-section">
      <nav class="items-list teachers-list">
        <a v-for="item in teachers"
           :key="item.id"
           v-bind:href="'#/TeacherDetail/' + item.id"
           class="list-item"
        >
          <span class="item-img">
            <img :src=item.photo :alt=item.name>
          </span>
          <span>{{item.name}}</span>
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      teachers: []
    }
  },
  methods: {
    loadJsonLectors () {
      this.lectorsJson.get().then(response => response.json())
        .then(response => {
          this.teachers = response
        })
    }
  },
  mounted () {
    this.loadJsonLectors()
  },
  computed: {
  },
  created () {
    this.lectorsJson = this.$resource('lectors')
  }
}
</script>
