<template>
  <div>
    <div class="section-bottom">
      <div class="basket-icon" v-if="res === 0">
        <span href class="link-basket">
          <span class="basket-icon-num">{{countCart}}</span>
          <i class="icon icon-basket"></i>
        </span>
      </div>
      <div class="basket-icon basket-full" v-if="res > 0">
        <a href="#/Basket" class="link-basket">
          <span class="basket-icon-num">{{countCart}}</span>
          <i class="icon icon-basket"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countCart: Number
  },
  data () {
    return {
      basketVal: 0
    }
  },
  computed: {
    res () {
      return this.countCart
    }
  },
  mounted () {
    this.getCountCart()
    // console.log(this.countCart)
  },
  methods: {
    getCountCart () {
      if (localStorage.getItem('itemsIdBasket')) {
        let basketValss = JSON.parse(localStorage.getItem('itemsIdBasket'))
        let countSum = 0
        for (var ss in basketValss) {
          countSum += basketValss[ss]
        }
        this.basketVal = countSum
      } else {
        this.basketVal = 0
      }
    }
  }
}
</script>
