<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="/" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{
            $ml.with("VueJS").get("settingsTitle")
          }}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="items-list-holder">
        <nav class="items-list">
          <div class="list-item list-item-setting">
            <span>{{ $ml.with("VueJS").get("notifications") }}</span>
            <span class="tgl-holder">
              <input
                class="tgl"
                id="notifications"
                type="checkbox"
                v-model="pushSender"
                v-bind:true-value="on"
                v-bind:false-value="off"
              />
              <label class="tgl-btn" for="notifications"></label>
            </span>
          </div>
          <!-- <a href="#/SettingsLang" class="list-item list-item-setting">
            <span>{{$ml.with('VueJS').get('langTitle')}}</span>
            <span class="list-item-setting-val">{{lang}}</span>
          </a> -->
          <a href="#/SettingsFont" class="list-item list-item-setting">
            <span>{{ $ml.with("VueJS").get("fontSize") }}</span>
            <span class="list-item-setting-val">{{ font }}</span>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // title: this.$ml.with('VueJS').get('settingsTitle'),
      lang: localStorage.getItem("lang"),
      on: true,
      off: false,
      pushSender: localStorage.getItem("pushSender"),
      font: localStorage.getItem("font"),
      size: ""
    };
  },
  mounted() {
    this.saveLocalStorage();
    this.isLang(localStorage.activeItem);
  },
  methods: {
    saveLocalStorage() {
      localStorage.setItem("pushSender", this.pushSender);
    },
    isLang(lang) {
      if (lang === "0") {
        this.$ml.change("ru");
      } else if (lang === "1") {
        this.$ml.change("ua");
      }
    }
  },
  watch: {
    pushSender(pushSender) {
      localStorage.pushSender = pushSender;
    }
  }
};
</script>
