<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/Teaching" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{$ml.with('VueJS').get('recording1')}}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="selecting-title">{{$ml.with('VueJS').get('selectCity')}}</div>
      <div class="items-list-holder">
        <nav class="items-list">
          <template
            v-for="(item, index) in cities"
          >
              <router-link
                class="list-item"
                v-if="item.id === 3"
                :to="item.to"
                :key="index"
                v-bind:class="{active: activeItem === index}"
                @click="onActiveItem(index)"
              >{{item.city}}</router-link>
              <span
                v-else
                class="list-item"
                :key="index"
                v-bind:class="{active: activeItem === index}"
                @click="onActiveItem(index)"
              >{{item.city}}</span>
          </template>
        </nav>
      </div>
    </div>
    <div class="section-bottom">
      <a href="#/TrainingRegistration_2" @click="next" class="btn btn-full-width btn-main">{{$ml.with('VueJS').get('proceed')}}</a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    if (localStorage.getItem('activeItem') === '1') {
      return {
        trainingCityId: localStorage.getItem('trainingCityId'),
        cityName: '',
        cities: [
          {
            city: 'Нові Санжари', id: 0, to: ''
          },
          {
            city: 'Львів', id: 1, to: ''
          },
          {
            city: 'Хмельницький', id: 2, to: ''
          },
          {
            city: 'Інше', id: 3, to: '/SelectingCity'
          }
        ],
        activeItem: localStorage.getItem('trainingCityId') | 0
      }
    }
    return {
      trainingCityId: localStorage.getItem('trainingCityId'),
      cityName: '',
      cities: [
        {
          city: 'Новые Санжары', id: 0, to: ''
        },
        {
          city: 'Львов', id: 1, to: ''
        },
        {
          city: 'Хмельницкий', id: 2, to: ''
        },
        {
          city: 'Другое', id: 3, to: '/SelectingCity'
        }
      ],
      activeItem: localStorage.getItem('trainingCityId') | 0
    }
  },
  methods: {
    next (event) {
      event.preventDefault()
      localStorage.setItem('trainingCity', this.cities[this.activeItem].city)
      localStorage.setItem('trainingCityId', this.cities[this.activeItem].id)
      this.$router.push('/TrainingRegistration_2')
    },
    onActiveItem: function (index) {
      this.activeItem = index
    }
  }
}
</script>
