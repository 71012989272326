<template>
  <div class="page-container catalog-detail teacher-detail">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/Teachers" class="link-back">
          <i class="icon icon-arrow-back-white"></i>
        </a>
      </div>
    </div>
    <div class="section-main">
      <div class="detail-image">
        <div class="detail-image-frame">
          <img v-bind:src="resultPhoto" v-bind:alt="resultName">
        </div>
        <span class="detail-img-bg"></span>
      </div>
      <div class="detail-info">
        <div class="detail-title">{{resultName}}</div>
        <div class="detail-info__container">
          <div class="request-row">
            <span class="request-name">{{$ml.with('VueJS').get('placeOfTeaching')}}</span>
            <span class="request-val" v-for="item in resultsRegion" :key="item.id">{{item.name}}</span>
          </div>
          <div class="request-row">
            <span class="request-name">{{$ml.with('VueJS').get('specialization')}}</span>
            <span class="request-val">{{$ml.with('VueJS').get('Cat1')}} Case IH серий Puma, Magnum, Steiger</span>
          </div>
          <div class="detail-info__description">
            <p>{{resultDescription}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      teachers: []
    }
  },
  methods: {
    async loadJsonLectors () {
      this.lectorsJson.get().then(response => response.json())
        .then(response => {
          this.teachers = response
        })
    }
  },
  mounted () {
    this.loadJsonLectors()
  },
  computed: {
    resultsRegion: function () {
      return this.teachers.region
    },
    resultName: function () {
      return this.teachers.name
    },
    resultPhoto: function () {
      return this.teachers.photo
    },
    resultDescription: function () {
      return this.teachers.description
    }
  },
  created () {
    this.lectorsJson = this.$resource('lectors/' + this.$route.params['id'])
  }
}
</script>
