<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="/index.html" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">
            <i class="icon icon-repair-part"></i>
            {{ $ml.with("VueJS").get("labelSpareParts") }}
          </span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <form id="search" class="form-search">
        <div class="form-group-search">
          <v-autocomplete
            :items="items"
            v-model="itemsss"
            :get-label="getLabel"
            :min-len="4"
            :wait="500"
            @update-items="update"
            :component-item="tpl"
            @item-selected="itemSelected"
            @item-clicked="itemClicked"
            :input-attrs="{
              name: 'input-test',
              id: 'v-my-autocomplete',
              placeholder: $ml.with('VueJS').get('skuOrName'),
            }"
            class="input input-search"
            @blur="inputFocus = false"
            @focus="inputFocus = true"
          ></v-autocomplete>

          <button
            type="submit"
            class="btn btn-search"
            v-on:click="rederectSearch"
            v-bind:class="{ 'btn-active': inputFocus }"
          >
            {{ $ml.with("VueJS").get("find") }}
          </button>
        </div>
      </form>
      <Loader v-if="this.loading" />
      <div v-else class="product-item-result">
        <div class="product-item-total">
          <span v-if="itemsSelected.length > 0"
            >{{ $ml.with("VueJS").get("found") }} {{ itemsSelected.length }}
            {{
              units(
                $ml.with("VueJS").get("detail1"),
                $ml.with("VueJS").get("detail2"),
                $ml.with("VueJS").get("detail3"),
                itemsSelected.length
              )
            }}</span
          >
          <span v-else-if="itemsSelected.length === 0">
            <NoResult />
          </span>
        </div>
        <div class="product-items">
          <template v-for="item in itemsSelected">
            <div
              class="product-item-holder"
              v-bind:key="item.id"
              v-if="item.status === '1'"
            >
              <div class="product-item">
                <div
                  v-if="!Number(item.quantity)"
                  class="product-item-status"
                  v-bind:class="''"
                >
                  {{ $ml.with("VueJS").get("notAvailable") }}
                </div>
                <div
                  v-else
                  class="product-item-status"
                  v-bind:class="'available'"
                >
                  {{ $ml.with("VueJS").get("available") }}
                </div>
                <span class="product-item-link">
                  <span class="product-item-name">{{ item.name }}</span>
                </span>
                <span class="product-item-code">Арт. {{ item.article }}</span>

                <span v-if="item.mpn === '1'" class="product-item-min_price">
                  <i class="ic-best_price"></i>
                  <span class="product-item-min_price-title">{{
                    $ml.with("VueJS").get("minPrice")
                  }}</span>
                </span>

                <template v-if="Number(item.quantity)">
                  <div class="product-item-price" v-if="item.price">
                    <div class="product-item-price-num">
                      {{ (item.price * item.value).toFixed(2) | format }} грн
                    </div>
                    <div class="product-item-price-text">
                      {{ $ml.with("VueJS").get("VAT") }}
                    </div>
                  </div>
                  <div class="product-item-price" v-else>
                    <div class="product-item-price-text">
                      {{ $ml.with("VueJS").get("checkPrice") }}
                    </div>
                  </div>
                  <div
                    class="product-item-buy"
                    v-if="Number(item.quantity) && item.allow_to_buy"
                  >
                    <div class="product-item-count">
                      <number-input
                        v-bind:true-value="test"
                        v-model="item.value"
                        :min="1"
                        inline
                        controls
                        rounded
                      ></number-input>
                    </div>
                    <button
                      class="btn btn-buy btn-main"
                      @click="addToBasket(item.id, item.value, item.price)"
                    >
                      {{ $ml.with("VueJS").get("buy") }}
                    </button>
                  </div>
                </template>
                <div class="product-item-links">
                  <span
                    v-if="item.analog.length === 0 && !item['has_analog ']"
                    class="product-item-more"
                    >{{ $ml.with("VueJS").get("analoguesN") }}</span
                  >
                  <span
                    v-else-if="item.analog.length === 0 && item['has_analog ']"
                    class="product-item-more link-red"
                    >{{ $ml.with("VueJS").get("analoguesY") }}</span
                  >
                  <a
                    v-else
                    v-bind:href="'#/Analogue/' + item.id"
                    class="product-item-more link-red"
                    >{{ $ml.with("VueJS").get("analoguesY") }}</a
                  >
                  <a
                    v-bind:href="'#/AvailabilityModal/' + item.id"
                    class="product-item-available link-red"
                    >{{ $ml.with("VueJS").get("checkAvailable") }}</a
                  >
                </div>
              </div>
            </div>
            <div class="product-item-holder" v-bind:key="item.id" v-else>
              <div class="product-item">
                <div
                  v-if="!Number(item.quantity)"
                  class="product-item-status"
                  v-bind:class="''"
                >
                  {{ $ml.with("VueJS").get("notAvailable") }}
                </div>
                <span class="product-item-link">
                  <span class="product-item-name">{{ item.name }}</span>
                </span>
                <span class="product-item-code">{{ item.article }}</span>
                <template v-if="false">
                  <div class="product-item-price">
                    <!--                    <div class="product-item-price-num">{{((item.price * item.value).toFixed(2)) | format}} грн</div>-->
                    <!--                    <div class="product-item-price-text">Цена с НДС</div>-->
                  </div>
                  <div class="product-item-buy" v-if="Number(item.quantity)">
                    <div class="product-item-count">
                      <number-input
                        v-bind:true-value="test"
                        v-model="item.value"
                        :min="1"
                        inline
                        controls
                        rounded
                      ></number-input>
                    </div>
                    <button
                      class="btn btn-buy btn-main"
                      @click="addToBasket(item.id, item.value, 0)"
                    >
                      {{ $ml.with("VueJS").get("buy") }}
                    </button>
                  </div>
                </template>
                <template>
                  <span
                    v-if="item.analog === 'true'"
                    class="product-item-more link-red"
                    >{{ $ml.with("VueJS").get("analoguesY") }}</span
                  >
                  <span v-else class="product-item-more">{{
                    $ml.with("VueJS").get("analoguesN")
                  }}</span>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <Cart v-bind:countCart="basketVal" />
  </div>
</template>

<script>
import tpl from "./ItemTemplate.vue";
import Parts from "./repair.js";
import {
  SEARCH_PRODUCTS,
  GET_USERS,
  LOG_LOGOUTS,
} from "../../helpers/requests.js";

export default {
  props: {
    item: { required: true },
    searchText: { required: true },
  },
  computed: {
    itemsss() {
      return this.searchText;
    },
  },
  data() {
    return {
      test: "",
      basketVal: 0,
      value: 1,
      itemsApi: [],
      cart: {},
      itemsSelected: [],
      items: [],
      tpl: tpl,
      loading: true,
      inputFocus: false,
    };
  },
  mounted() {
    this.loadJsonSearch();
    this.checkAuth();
  },
  methods: {
    async sendLogoutLogs(errorMessage) {
      try {
        await LOG_LOGOUTS(JSON.stringify(errorMessage));
      } catch (error) {
        console.log(error.message);
      }
    },
    units(first, second, third, count = 1) {
      var output = "";
      if (count - Math.floor(count) !== 0) {
        output = first;
      } else {
        if (count % 10 === 1 && count % 100 !== 11) {
          output = first;
        } else if (
          count % 10 >= 2 &&
          count % 10 <= 4 &&
          (count % 100 >= 20 || count % 100 < 10)
        ) {
          output = second;
        } else {
          output = third;
        }
      }
      return output;
    },
    setLocalStorage(text) {
      localStorage.setItem("search", text);
    },
    setGetLocalStorage() {
      localStorage.setItem("itemsIdBasket", JSON.stringify(this.cart));
    },
    getCountCart(id, count) {
      var basketValss = JSON.parse(localStorage.getItem("itemsIdBasket"));
      var countSum = 0;
      for (var ss in basketValss) {
        if (ss === id) {
          countSum += basketValss[ss].count;
        }
      }
      this.basketVal = countSum;

      localStorage.setItem("itemsIdBasket", JSON.stringify(this.cart));
    },
    addToBasket(id, value, price) {
      this.cart = JSON.parse(localStorage.getItem("itemsIdBasket"));
      if (this.cart === null) {
        this.cart = {};
      }

      if (this.cart[id]) {
        this.cart[id]["count"] += value;
      } else {
        this.cart[id] = {
          count: value,
          price: price,
        };
      }
      this.setGetLocalStorage();
      this.countCartt();
    },
    async loadJsonSearch() {
      this.countCartt();
      // await this.searchJson.get()
      await SEARCH_PRODUCTS(
        localStorage.search +
          "&token=" +
          localStorage.userToken +
          "&id=" +
          localStorage.userId
      )
        .then((response) => response.json())
        .then((response) => {
          for (var tt in response) {
            response[tt]["value"] = 1;
          }
          this.itemsSelected = response;
        });
      this.loading = false;
    },
    countCartt() {
      let countSum = 0;
      if (localStorage.getItem("itemsIdBasket")) {
        let basketValss = JSON.parse(localStorage.getItem("itemsIdBasket"));
        for (var ss in basketValss) {
          countSum += basketValss[ss].count;
        }
        this.basketVal = countSum;
        return this.basketVal;
      }
    },
    rederectSearch(e) {
      e.preventDefault();
      this.$router.replace("/SearchItemsResult");
    },
    async loadSearch(search) {
      console.log(search);
      await SEARCH_PRODUCTS(
        search +
          "&token=" +
          localStorage.userToken +
          "&id=" +
          localStorage.userId
      )
        .then((response) => response.json())
        .then((response) => {
          for (var tt in response) {
            response[tt]["value"] = 1;
            console.log(response[tt]);
          }
          this.itemsSelected = response;
        });
    },
    itemSelected(item) {
      console.log("Selected item!", item);
    },
    itemClicked(item) {
      console.log("You clicked an item!", item);
    },
    getLabel(item) {
      if (item) {
        return item.name;
      }
      return "";
    },
    update(text) {
      this.loadSearch(text.toLowerCase());
      this.setLocalStorage(text.toLowerCase());
      if (localStorage.getItem("search")) {
        this.loadSearch(localStorage.getItem("search"));
      } else {
        this.loadSearch(text.toLowerCase());
      }
      this.items = Parts.filter((item) => {
        return new RegExp(text.toLowerCase()).test(item.name.toLowerCase());
      });
    },
    checkAuth: async function () {
      if (localStorage.userId) {
        this.users = await GET_USERS(localStorage.userId)
          .then((response) => response.json())
          .then((response) => {
            if (
              typeof response === "object" &&
              Object.keys(response).length === 0
            ) {
              this.sendLogoutLogs({
                data: `Користувач ${localStorage.userName} з id ${localStorage.userId} прийшов пустим (SearchItemsResult.404)`,
              });
              localStorage.clear();
            }
            if (
              typeof response.can_use_app === "boolean" &&
              !response.can_use_app
            ) {
              this.sendLogoutLogs({
                data: `Користувач ${localStorage.userName} з id ${localStorage.userId} не має доступу до додатку (SearchItemsResult.413)`,
              });
              localStorage.clear();
            }
          });
      }
    },
  },
  filters: {
    format(value) {
      return value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 ");
    },
  },
  created() {
    this.searchJson = this.$resource(
      "search&q=" +
        localStorage.getItem("search") +
        "&token=" +
        localStorage.token +
        "&id=" +
        localStorage.userId
    );
  },
};
</script>

<style>
.raz {
  all: unset;
  -moz-appearance: textfield;
  width: 3em;
  text-align: center;
}
.raz::-webkit-inner-spin-button {
  display: none;
}
</style>
