<template>
  <div class="page-container page-success">
    <div class="section-main">
      <div class="page-success-holder">
        <div class="icon icon-success"></div>
        <div>
          Ми надіслали новий пароль на пошту <strong>{{ userEmail }}</strong>
        </div>
      </div>
    </div>
    <div class="section-bottom">
      <a href="#/Login" class="btn btn-full-width btn-main"
        >Увійти до облікового запису</a
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userEmail: null
    };
  },
  methods: {
    saveLocalStorage() {
      if (localStorage.userEmail) {
        this.userEmail = localStorage.userEmail;
      }
      console.log(localStorage);
    }
  },
  mounted() {
    this.saveLocalStorage();
  }
};
</script>
