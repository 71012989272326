export default {
  data () {
    return {
      id: '',
      name: '',
      code: '',
      href: ''
    }
  }
}
//   {
//     id: 1,
//     name: 'Шина (152.26.020), Alliance A-570 AS, MPT',
//     code: 'Арт. 19.5LR24, 156A8/153B, TL',
//     href: '#/SearchItemResult'
//   },
//   {
//     id: 2,
//     name: 'Шина (152.26.020), Alliance A-57',
//     code: 'Арт. 19.555',
//     href: '#/SearchItemsResult'
//   },
//   {
//     id: 3,
//     name: 'Шина (125.00.250), Carlisle Multitrac',
//     code: 'Арт. 19.5LR24, 156A8/153B, TL',
//     href: '#/SearchNoResult'
//   },
//   {
//     id: 4,
//     name: 'Шина (152.26.020), Alliance A-570 AS, MPT',
//     code: 'Арт. 19.5LR24, 156A8/153B, TL',
//     href: '#/SearchItemResult'
//   },
//   {
//     id: 5,
//     name: 'Шина (152.26.020), Alliance A-57',
//     code: 'Арт. 19.5LR24',
//     href: '#/SearchItemResult'
//   },
//   {
//     id: 6,
//     name: 'Шина (125.00.250), Carlisle Multitrac',
//     code: 'Арт. 19.5LR24, 156A8/153B, TL',
//     href: '#/SearchItemResult'
//   },
//   {
//     id: 7,
//     name: 'Шина (125.00.250), Carlisle Multitrac 1',
//     code: 'Арт. 19.5LR24, 156A8/153B, TL 1',
//     href: '#/SearchItemResult'
//   },
//   {
//     id: 8,
//     name: 'Шина (125.00.250), Carlisle Multitrac 2',
//     code: 'Арт. 19.5LR24, 156A8/153B, TL 2',
//     href: '#/SearchItemResult'
//   },
//   {
//     id: 9,
//     name: 'Шина (125.00.250), Carlisle Multitrac 3',
//     code: 'Арт. 19.5LR24, 156A8/153B, TL 3',
//     href: '#/SearchItemResult'
//   },
//   {
//     id: 10,
//     name: 'Шина (125.00.250), Carlisle Multitrac 4',
//     code: 'Арт. 19.5LR24, 156A8/153B, TL 4',
//     href: '#/SearchItemResult'
//   },
//   {
//     id: 11,
//     name: 'Шина (125.00.250), Carlisle Multitrac 5',
//     code: 'Арт. 19.5LR24, 156A8/153B, TL 5',
//     href: '#/SearchItemResult'
//   },
//   {
//     id: 12,
//     name: 'Шина (125.00.250), Carlisle Multitrac 6',
//     code: 'Арт. 19.5LR24, 156A8/153B, TL 6',
//     href: '#/SearchItemResult'
//   }
