<script>
export default {
  name: 'modal',
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
           role="dialog"
           aria-labelledby="modalTitle"
           aria-describedby="modalDescription"
      >
        <svg style="margin: auto; padding-top: 20px" width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 18L35 35" stroke="#C70629" stroke-width="3"/>
          <path d="M35 18L18 35" stroke="#C70629" stroke-width="3"/>
          <circle cx="26.5" cy="26.5" r="25" stroke="#C70629" stroke-width="3"/>
        </svg>
        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
            <!--<div class="modal-title">Упс!</div>-->
            <div class="modal-description">{{$ml.with('VueJS').get('checkConnection')}}</div>
          </slot>
        </section>
        <footer class="modal-footer">
          <slot name="footer">
            <button
              type="button"
              class="btn-green"
              @click="close"
              aria-label="Закрыть"
            >
              {{$ml.with('VueJS').get('close')}}

            </button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
<style>
  .modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    margin-bottom: 10px;
  }
  .modal-description{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #000000;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    width: 80%;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }
  .modal-footer {
    padding: 15px;
    display: flex;
    margin: auto;
  }
  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-green {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #C70629;
    border: none;
    background: #fff;
    outline: none;
  }
</style>
