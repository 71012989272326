<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/Settings" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{$ml.with('VueJS').get('fontSize')}}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="items-list-holder">
        <nav class="items-list items-list-font">
          <span
            class="list-item"
            v-for="(item , index) in fonts"
            :key="index"
            v-bind:style="{ fontSize: item.size + 'em' }"
            :class="{ 'active': activeItemFont === index }"
            v-on:click="onActiveItem( index) "
          >{{$ml.db[lang].fonts[index].font}}</span>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      lang: localStorage.getItem('activeItem'),
      // title: this.$ml.with('VueJS').get('fontSize'),
      activeItemFont: Number(localStorage.getItem('activeItemSize')),
      fonts: [
        { font: 'Маленький', size: 0.8 },
        { font: 'Средний', size: 1 },
        { font: 'Большой', size: 1.2 }
      ]
    }
  },
  mounted () {
    this.isLang(localStorage.activeItem)
  },
  methods: {
    onActiveItem: function (index) {
      this.activeItemFont = index
      localStorage.setItem('size', this.fonts[this.activeItemFont].size)
      localStorage.setItem('activeItemSize', this.activeItemFont)
      localStorage.setItem('font', this.$ml.db[this.lang].fonts[this.activeItemFont].font)
      console.log(this.$ml.db[this.lang].fonts[this.activeItemFont].font)
      // localStorage.setItem('font', this.fonts[this.activeItem].font)
    },
    isLang: function (lang) {
      if (lang === '0') {
        this.$ml.change('ru')
      } else if (lang === '1') {
        this.$ml.change('ua')
      }
    }
  }
}
</script>
