<template>
  <div>
    <modal
      name="success-request-equipment"
      :adaptive="true"
      :resizable="true"
      height="auto"
      id="success-request-equipment"
    >
      <div class="icon icon-success"></div>
      <div class="title-success">{{$ml.with('VueJS').get('msgSuccessTitle')}}</div>
      <div class="modal-text">{{$ml.with('VueJS').get('msgSuccessText')}}</div>
      <div class="modal-buttons">
        <a v-bind:href="'#/CatalogDetail/' + idCategory + '/' + idProduct" class="btn link-red">{{$ml.with('VueJS').get('understand')}}</a>
      </div>
    </modal>
  </div>
</template>
<script>
export default {
  data () {
    return {
      idCategory: this.$route.params['idCategory'],
      idProduct: this.$route.params['idProduct']
    }
  }
}
</script>
