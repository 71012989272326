<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/Settings" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item" v-text="$ml.with('VueJS').get('langTitle')"></span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="items-list-holder">
        <nav class="items-list">
          <span
            class="list-item"
            v-for="(item , index) in $ml.list"
            :key="item"
            :class="{ 'active': activeItem === index }"
            v-on:click="onActiveItem( index), $ml.change(item)"
            v-bind:id="activeItem"
          >{{$ml.db[activeItem].langs[index].lang}}</span>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // title: 'Язык',
      activeItem: Number(localStorage.getItem('activeItem')),
      langs: [{ lang: 'Русский' }, { lang: 'Українська' }],
      lang: ''
    }
  },
  methods: {
    onActiveItem: function (index) {
      this.activeItem = index
      localStorage.setItem('lang', this.langs[this.activeItem].lang)
      localStorage.setItem('activeItem', this.activeItem)
      localStorage.setItem('font', this.$ml.db[this.activeItem].fonts[(localStorage.activeItemSize)].font)
    },
    isLang: function (lang) {
      if (lang === '0') {
        this.$ml.change('ru')
      } else if (lang === '1') {
        this.$ml.change('ua')
      }
    }
  },
  watch: {
    langs (langs) {
      localStorage.name = langs
    },
    activeItem (activeItem) {
      localStorage.activeItem = activeItem
    }
  },
  mounted () {
    this.isLang(localStorage.activeItem)
  }
}
</script>
