<template>
  <div class="page-container page-success">
    <div class="section-main">
      <div class="page-success-holder">
        <div class="icon icon-success"></div>
        <div class="title-success">{{$ml.with('VueJS').get('successTrainingTitle')}}</div>
        <div>{{$ml.with('VueJS').get('successTrainingMsg')}}</div>
      </div>
    </div>
    <div class="section-bottom">
      <a href="#/Teaching " class="btn btn-full-width btn-main">{{$ml.with('VueJS').get('understand')}}</a>
    </div>
  </div>
</template>

<script>
module.exports = {
  data: function () {
    return {
    }
  }
}
</script>
