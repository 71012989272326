import Vue from "vue";
import { MLInstaller, MLCreate, MLanguage } from "vue-multilanguage";

Vue.use(MLInstaller);

export default new MLCreate({
  initial: "ru",
  save: process.env.NODE_ENV === "production",
  languages: [
    new MLanguage("ru").create({
      langs: [{ lang: "Русский" }, { lang: "Украинский" }],
      phone_new: "Связаться с нами",
      personal1: "Редактировать данные",
      personal2: "Изменить пароль",
      personal3: "Выйти",
      noResult: "По вашему запросу ничего не найдено",
      noResultTxt: "Скорректируйте или введите другой поисковой запрос",
      logoText: "Официальный дистрибьютор техники Massey Ferguson",
      mapTitle: "Филиалы",
      shortDesc: "Краткое описание",
      discusOfApp: "Обсуждение заявки",
      sortBy: "Сортировать по",
      byDate: "Дате",
      byStatus: "Статусу",
      createNewRequest: "Создать новую заявку",
      createNewRequestTitle: "Создание новой заявки",
      brand: "Бренд",
      setBrand: "Укажите бренд",
      techName: "Наименование техники",
      setTechName: "Укажите наименование техники",
      model: "Модель",
      setModel: "Укажите модель",
      serialNum: "Серийный номер",
      setSerialNum: "Cерийный номер должен содержать не менее 6 символов",
      repairСategory: "Категория ремонта",
      setRepairСategory: "Выберите категорию ремонта",
      repairHolding: "Дочернее предприятие",
      setRepairHolding: "Выберите дочернее предприятие",
      vehicleRegion: "Область",
      vehicleDistrict: "Район",
      serviceCentre: "Сервисный центр",
      setvehicleDistrict: "Укажите район",
      locality: "Населенный пункт",
      setLocality: "Укажите населенный пункт",
      workingHours: "Наработка",
      setWorkingHours: "Наработка должна содержать не менее 2 символов",
      faultDescription: "Описание неисправности",
      setFaultDescription: "Опишите неисправности",
      setFile: "Прикрепить файл",
      callDispatcher: "Позвонить диспетчеру",
      rateServiceSuccess: "Спасибо за вашу оценку!",
      rateService: "Оценить сервис",
      rateServiceComment: "Напишите пожалуйста что можно улучшить",
      rateServiceMsg: "Нам очень важна ваша оценка чтобы становиться лучше",
      rateServiceTxt: "Опишите неисправности",
      сomment: "Комментарий",
      setComment: "Введите комментарий",

      training: "Обучение",
      trainingReg: "Запись на обучение",
      lecturers: "Лекторы",
      lecturer: "Лектор",
      myApp: "Мои заявки",
      recording1: "Запись. Шаг 1",
      recording2: "Запись. Шаг 2",
      recording3: "Запись. Шаг 3",
      recording4: "Запись. Шаг 4",
      recording5: "Запись. Шаг 5",
      selectCity: "Выберите город",
      proceed: "Продолжить",
      selectTopic: "Выберите тему обучения",
      desiredDates: "Желаемые даты",
      selectInterval: "Выберите интервал чтобы продолжить",
      numberOfPersons: "Количество человек",
      selectNumberOfPersons: "Выберите количество человек",
      commentOptional: "Комментарий (необязательно)",
      checkInfo: "Проверьте введенную вами информацию",
      city: "Город",
      topic: "Тема",
      desiredDate: "Желаемая дата",
      signUp: "Записаться",
      successTrainingTitle: "Спасибо, вы записались на обучение!",
      successTrainingMsg: "С вами свяжется наш менеджер",
      selectingCity1:
        "Введите в поле ниже место где бы вы хотели провести обучение",
      selectingCity2: "Место проведения",
      selectingCity3: "Укажите город",
      add: "Добавить",
      placeOfTeaching: "Место преподавания",
      specialization: "Специализация",

      name: "Имя",
      surname: "Фамилия",
      setNameSurname: "Укажите имя и фамилию",
      setNameSurnameError: "Поле Имя и Фамилия не должно содержать цифр",
      setEmail: "Укажите E-mail",
      setPhoneNum: "Укажите номер телефона",
      deleteModalTitle: "Вы точно хотите удалить все товары из корзины?",
      checkConnection: "Проверьте соединение с интернетом",
      close: "Закрыть",

      Cat1: "Тракторы",
      Cat2: "Комбайны",
      Cat3: "Жатки",
      Cat4: "Опрыскиватели",
      Cat5: "Сеялки",
      Cat6: "Почвообработка",
      Cat7: "Другая техника",
      Cat8: "Пресс-подборщики",
      Cat9: "Точное земледелие",
      Cat10: "Техника б/у",
      cart: "Корзина",
      clear: "Очистить",
      deleteProduct: "Удалить товар",
      basketMsg: "Окончательная цена будет сформирована менеджером",
      checkoutFor: "Оформить заказ на",
      found: "Найдено",
      detail1: "деталь",
      detail2: "детали",
      detail3: "деталей",
      notAvailable: "Нет в наличии",
      available: "В наличии",
      VAT: "Цена с НДС",
      checkPrice: "Цену уточняйте у менеджера",
      buy: "Купить",
      analoguesY: "Есть аналоги",
      analoguesN: "Нет аналогов",
      checkAvailable: "Проверить наличие",
      availabilityItem: "Наличие запчасти",
      analogs: "Аналоги",
      skuOrName: "Артикул или название",
      find: "Найти",
      save: "Сохранить",
      technics: "Техника",
      spareParts: "Запчасти",
      labelSpareParts: "Запасные части",
      service: "Сервис",
      getDirections: "Проложить маршрут",
      SendRequest: "Подать заявку",
      SendRequest2: "Отправить запрос",
      send: "Отправить",
      сancel: "Отменить",
      delete: "Удалить",
      sendRequestQ: "Отправить заявку?",
      msgSuccessOrder: "Заказ оформлен!",
      msgSuccessTitle: "Заявка отправлена!",
      msgSuccessText: "С вами свяжется наш менеджер",
      understand: "Понятно",
      techRequest: "Заявка на технику",
      catalogTitle: "Каталог \n техники",
      catalogTitleLine: "Каталог техники",
      sparePartTitle: "Запасные \n части",
      orderServices: "Заявки на сервис",
      orderServicesTitle: "Заявки на \n сервис",
      teachingTitle: "Обучение \n персонала",
      informationTitle: "Информация",
      personalTitle: "Личный кабинет",
      codexTitle: "Кодекс корпоративной этики",
      privacyPolicy: "Политика конфиденциальности",
      usingTitle: "Условия использования",
      langTitle: "Язык",
      settingsTitle: "Налаштування",
      notifications: "Повідомлення",
      fontSize: "Розмір шрифту",
      fonts: [{ font: "Маленький" }, { font: "Средний" }, { font: "Большой" }],
      labelFullName: "ФИО",
      labelEmail: "E-mail",
      labelPhone: "Телефон",
      setPhone: "Укажите телефон",
      labelRegion: "Регион",
      setRegion: "Укажите регион",
      labelContactPerson: "Контактное лицо",
      setContactPerson: "Укажите контактное лицо",
      changeContactPerson: "Изменить контактное лицо",
      labelNumber: "ЕГРПОУ",
      labelCompany: "Название предприятия",
      logout: "Выйти",
      codexSubtitle1: "О клиентах",
      minPrice: "Цена на данную позицию минимальная",
      codexSectionText1: [
        {
          text: "Мы стремимся быть компанией, у которой самые лояльные клиенты."
        },
        {
          text: "Мы готовы свернуть горы, чтобы хорошо обслужить клиента."
        },
        {
          text:
            "Если мы не можем этого сделать сами, то привлекаем и просим помощи у других сотрудников."
        },
        {
          text:
            "Наши прайсы, расценки и условия предоставления услуг должны быть максимально понятными, прозрачными и справедливыми для поставщиков и клиентов."
        },
        {
          text:
            "В общении с клиентами мы должны быть не только самыми профессиональными, но и самыми приветливыми и вежливыми. Мы улыбаемся нашим клиентам. Делаем это искренне и как можно чаще, ведь мы благодарны им за то, что они наши клиенты!"
        },
        {
          text:
            "Качество обслуживания наших клиентов должно быть не просто хорошим, а лучшим на рынке сельхозтехники в Украине. Для этого мы должны не только соответствовать ожиданиям клиента, но превосходить их. Мы обслуживаем клиента так, как хотели бы, чтобы обслуживали нас."
        },
        {
          text:
            "Если мы видим, что на предприятии есть системная ошибка, которая вызывает недовольство клиентов и мешает качественно обслуживать их, мы делаем всё, чтобы ее исправить. Вплоть до личного обращения к учредителям компании."
        },
        {
          text:
            "Мы стараемся не быть навязчивыми! Мы предлагаем клиенту только те товары, услуги и сервис, которые ему действительно нужны."
        }
      ],
      codexSubtitle2: "О руководителях",
      codexSectionText2: [
        {
          text:
            "Руководитель ценится за текущие достижения, а не за прошлые заслуги."
        },
        {
          text:
            "Руководители относятся к своим подчиненным с большим уважением."
        },
        {
          text: "Руководители приветствуют инициативу своих подчиненных."
        },
        {
          text: "Руководители внимательны к личным проблемам сотрудников."
        },
        {
          text:
            "Руководители должны быть доступными – отвечать на письма и звонки, быть готовыми лично встретиться с подчиненным."
        },
        {
          text:
            "Руководители не выдают идеи или проделанную работу сотрудников за свои достижения и не прячутся за спины подчиненных в случае неудач."
        },
        {
          text:
            "Руководители всё время думают, как сделать работу своих подчиненных более эффективной, чтобы сотрудники были заинтересованы в конечном результате и мотивированы делать работу качественно."
        }
      ],
      setFieldRequired: "Это поле является обязательным"
    }),
    new MLanguage("ua").create({
      langs: [{ lang: "Російська" }, { lang: "Українська" }],
      phone_new: "Зв'язатися з нами",
      personal1: "Редагувати дані",
      personal2: "Змінити пароль",
      personal3: "Вийти",
      logoText: "Офіційний дистриб'ютор техніки Massey Ferguson",
      noResult: "По вашому запиту нічого не знайдено",
      noResultTxt: "Скорегуйте або введіть інший пошуковой запит",
      mapTitle: "Філії",
      shortDesc: "Короткий опис",
      discusOfApp: "Обговорення заявки",
      sortBy: "Сортувати",
      byDate: "За датою",
      byStatus: "За статусом",
      createNewRequest: "Створити нову заявку",
      createNewRequestTitle: "Створення нової заявки",
      brand: "Бренд",
      setBrand: "Вкажіть бренд",
      techName: "Найменування техніки",
      setTechName: "Вкажіть найменування техніки",
      model: "Модель",
      setModel: "Вкажіть модель",
      serialNum: "Серійний номер",
      setSerialNum: "Серійний номер повинен мати не менше 6 символів",
      repairСategory: "Категорія ремонту",
      setRepairСategory: "Оберіть категорію ремонту",
      repairHolding: "Дочірнє підприємство",
      setRepairHolding: "Оберіть дочірнє підприємство",
      vehicleRegion: "Oбласть",
      vehicleDistrict: "Район",
      setVehicleLocation: "Вкажіть місцезнаходження",
      serviceCentre: "Сервісний центр",
      locality: "Населений пункт",
      setLocality: "Вкажіть населений пункт",
      workingHours: "Напрацювання",
      setWorkingHours: "Напрацювання має містити не менше 6 символів",
      faultDescription: "Опис несправності",
      setFaultDescription: "Опишіть несправності",
      setFile: "Прикріпити файл",
      callDispatcher: "Зателефонувати диспетчеру",
      rateServiceSuccess: "Дякую за вашу оцінку!",
      rateService: "Оцінити сервіс",
      rateServiceComment: "Напишіть будь ласка що можна поліпшити",
      rateServiceMsg: "Нам дуже важлива ваша оцінка щоб ставати краще",
      rateServiceTxt: "Опишіть несправності",
      setComment: "Введіть коментар",
      сomment: "Коментар",

      training: "Навчання",
      trainingReg: "Запис на навчання",
      lecturers: "Лектори",
      lecturer: "Лектор",
      myApp: "Мої заявки",
      recording1: "Запис. Крок 1",
      recording2: "Запис. Крок 2",
      recording3: "Запис. Крок 3",
      recording4: "Запис. Крок 4",
      recording5: "Запис. Крок 5",
      selectCity: "Оберіть місто",
      proceed: "Продовжити",
      selectTopic: "Виберіть тему навчання",
      desiredDates: "Бажані дати",
      selectInterval: "Оберіть інтервал щоб продовжити",
      numberOfPersons: "Кількість осіб",
      selectNumberOfPersons: "Оберіть кількість осіб",
      commentOptional: "Коментар (необов'язково)",
      checkInfo: "Перевірте введену вами інформацію",
      city: "Місто",
      topic: "Тема",
      desiredDate: "Бажана дата",
      signUp: "Записатися",
      successTrainingTitle: "Дякую, ви записалися на навчання!",
      successTrainingMsg: "З вами зв'яжеться наш менеджер",
      selectingCity1:
        "Введіть в поле нижче місце де б ви хотіли провести навчання",
      selectingCity2: "Місце проведення",
      selectingCity3: "Вкажіть місто",
      add: "Додати",
      placeOfTeaching: "Місце викладання",
      specialization: "Спеціалізація",

      name: "Ім'я",
      surname: "Прізвище",
      setNameSurname: "Вкажіть ім'я та прізвище",
      setNameSurnameError: "Поле Ім'я та Прізвище не повинно містити цифр",
      setEmail: "Вкажіть E-mail",
      setPhoneNum: "Вкажіть номер телефону",
      deleteModalTitle: "Ви точно хочете видалити всі товари з кошика?",
      checkConnection: "Перевірте з'єднання з інтернетом",
      close: "Закрити",

      Cat1: "Трактори",
      Cat2: "Комбайни",
      Cat3: "Жатки",
      Cat4: "Оприскувачі",
      Cat5: "Сівалки",
      Cat6: "Обробіток грунту",
      Cat7: "Інша техніка",
      Cat8: "Прес-підбирачі",
      Cat9: "Точне землеробство",
      Cat10: "Техніка б/в",
      cart: "Кошик",
      clear: "Очистити",
      deleteProduct: "Видалити товар",
      basketMsg: "Остаточна ціна буде сформована менеджером",
      checkoutFor: "Оформити замовлення на",
      found: "Знайдено",
      detail1: "деталь",
      detail2: "деталі",
      detail3: "деталей",
      notAvailable: "Немає в наявності",
      available: "В наявності",
      VAT: "Ціна з ПДВ",
      checkPrice: "Ціну уточняйте у менеджера",
      buy: "Купити",
      analoguesY: "Є аналоги",
      analoguesN: "Немає аналогів",
      checkAvailable: "Перевірити наявність",
      availabilityItem: "Наявність запчастини",
      analogs: "Аналоги",
      skuOrName: "Артикул або назва",
      find: "Знайти",
      save: "Зберегти",
      technics: "Техніка",
      spareParts: "Запчастини",
      labelSpareParts: "Запасні частини",
      service: "Сервіс",
      getDirections: "Прокласти маршрут",
      SendRequest: "Подати заявку",
      SendRequest2: "Відправити запит",
      send: "Відправити",
      сancel: "Відмінити",
      delete: "Видалити",
      sendRequestQ: "Відправити заявку?",
      msgSuccessOrder: "Замовлення оформлено!",
      msgSuccessTitle: "Заявку відправлено!",
      msgSuccessText: "З вами зв'яжеться наш менеджер",
      understand: "Зрозуміло",
      techRequest: "Заявка на техніку",
      catalogTitle: "Каталог \n техніки",
      catalogTitleLine: "Каталог техніки",
      sparePartTitle: "Запасні \n частини",
      orderServices: "Заявки на сервіс",
      orderServicesTitle: "Заявки на \n сервіс",
      teachingTitle: "Навчання \n персоналу",
      informationTitle: "Iнформацiя",
      personalTitle: "Особистий кабінет",
      codexTitle: "Кодекс корпоративної етики",
      privacyPolicy: "Політика конфіденційності",
      usingTitle: "Умови використання",
      langTitle: "Мова",
      settingsTitle: "Налаштування",
      notifications: "Повідомлення",
      fontSize: "Розмір шрифту",
      fonts: [{ font: "Маленький" }, { font: "Середній" }, { font: "Великий" }],
      labelFullName: "ПІБ",
      labelEmail: "E-mail",
      labelPhone: "Телефон",
      setPhone: "Вкажіть телефон",
      labelRegion: "Регіон",
      setRegion: "Вкажіть регіон",
      labelContactPerson: "Контактна особа",
      setContactPerson: "Вкажіть контактну особу",
      changeContactPerson: "Змінити контактну особу",
      labelNumber: "ЄДРПОУ",
      labelCompany: "Назва підприємства",
      logout: "Вийти",
      codexSubtitle1: "Про клієнтів",
      minPrice: "Ціна на дану позицію мінімальна",
      codexSectionText1: [
        {
          text: "Ми прагнемо бути компанією, у якої найбільш лояльні клієнти."
        },
        {
          text: "Ми готові згорнути гори, щоб добре обслужити клієнта."
        },
        {
          text:
            "Якщо ми не можемо цього зробити самі, то залучаємо і просимо допомоги у інших співробітників"
        },
        {
          text:
            "Наші прайси, розцінки і умови надання послуг повинні бути максимально зрозумілими, прозорими і справедливими для постачальників і клієнтів."
        },
        {
          text:
            "У спілкуванні з клієнтами ми повинні бути не тільки самими професійними, а й самими привітними і ввічливими. Ми посміхаємося нашим клієнтам. Робимо це щиро і як можна частіше, адже ми вдячні їм за те, що вони наші клієнти!"
        },
        {
          text:
            "Якість обслуговування наших клієнтів має бути не просто хорошим, а найкращим на ринку сільгосптехніки в Україні. Для цього ми повинні не тільки відповідати очікуванням клієнта, але перевершувати їх. Ми обслуговуємо клієнта так, як хотіли б, щоб обслуговували нас."
        },
        {
          text:
            "Якщо ми бачимо, що на підприємстві є системна помилка, яка викликає невдоволення клієнтів і заважає якісно обслуговувати їх, ми робимо все, щоб її виправити. Аж до особистого звернення до засновників компанії."
        },
        {
          text:
            "Ми намагаємося не бути нав'язливими! Ми пропонуємо клієнту тільки ті товари, послуги та сервіс, які йому дійсно потрібні."
        }
      ],
      codexSubtitle2: "Про керівників",
      codexSectionText2: [
        {
          text:
            "Керівник цінується за поточні досягнення, а не за минулі заслуги."
        },
        {
          text: "Керівники відносяться до своїх підлеглих з великою повагою."
        },
        {
          text: "Керівники вітають ініціативу своїх підлеглих."
        },
        {
          text: "Керівники уважні до особистих проблем співробітників."
        },
        {
          text:
            "Керівники повинні бути доступними - відповідати на листи і дзвінки, бути готовими особисто зустрітися з підлеглим."
        },
        {
          text:
            "Керівники не видають ідеї або виконану роботу співробітників за свої досягнення і не ховаються за спини підлеглих в разі невдач."
        },
        {
          text:
            "Керівники весь час думають, як зробити роботу своїх підлеглих більш ефективної, щоб співробітники були зацікавлені в кінцевому результаті і мотивовані робити роботу якісно."
        }
      ],
      setFieldRequired: "Це поле є обов'язковим"
    })
  ]
});
