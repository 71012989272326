import Vue from "vue";
import App from "./App.vue";
import VueResource from "vue-resource";
//import './registerServiceWorker'
import router from "./router";
import store from "./store";
import Navigation from "../src/components/Navigation";

import Loader from "../src/components/Loader";
import Cart from "../src/components/Cart";

import Vue2TouchEvents from "vue2-touch-events";
import VModal from "vue-js-modal";
import Vuelidate from "vuelidate";
import vSelect from "vue-select";
import Autocomplete from "v-autocomplete";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import * as VueGoogleMaps from "vue2-google-maps";
import VCalendar from "v-calendar";
import CatalogStart from "../src/components/CatalogStart";
import NoResult from "../src/components/NoResult";

import "./ml/ml";

import "v-autocomplete/dist/v-autocomplete.css";
import "../src/styles/main.scss";
// import headers from 'vue-resource/src/http/headers'

Vue.use(VueResource);
Vue.http.options.root = process.env.VUE_APP_MAIN_DOMAIN_URL;
Vue.http.headers = { "Access-Control-Allow-Origin": "*" };

// console.log(Vue.http)
// Vue.http.headers.common['Access-Control-Allow-Origin'] = '*'

// console.log(Vue.http)

Vue.config.productionTip = false;
Vue.component("Navigation", Navigation);
Vue.component("Cart", Cart);
Vue.component("CatalogStart", CatalogStart);
Vue.component("NoResult", NoResult);

Vue.component("Loader", Loader);

Vue.config.productionTip = false;
Vue.use(Vue2TouchEvents);
Vue.use(Vuelidate);
Vue.use(Autocomplete);
Vue.use(VueNumberInput);
Vue.component("v-select", vSelect);
Vue.use(VModal);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBf2n8_DsWmaP33Av4w1csqjsn6SuClNTI",
    libraries: "places,drawing,visualization",
  },
});

Vue.use(VCalendar, {
  formats: {
    title: "M YY",
    weekdays: "WW",
  },
  // componentPrefix: 'vc'
});

Vue.component("todo-item", {
  props: ["todo"],
  template: `<li>{{todo.text}}</li>`,
});

new Vue({
  // http: {
  //   headers: {
  //     'Access-Control-Allow-Origin': '*'
  //   }
  // },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// new Vue({
//   router,
//   components: { App },
//   template: '<App/>',
//   methods: {
//     show () {
//       this.$modal.show('hello-world')
//     },
//     hide () {
//       this.$modal.hide('hello-world')
//     }
//   }
//   render: h => h(App)
// }).$mount('#app')
