<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/SearchItemsResult" class="link-back">
          <i class="icon icon-close"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{$ml.with('VueJS').get('availabilityItem')}}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="product-item-detail">
        <span>{{ itemStorage.article }}</span>
      </div>
      <ul class="places-list">
        <li v-for="item in itemStorage.storage"
           :key="item">
          <span class="product-item-place">{{ item.store }}</span>
          <span v-if="item.amount > 5 && !itemStorage.has_service_store" class="product-item-value">>5 шт.</span>
          <span v-else class="product-item-value">{{ item.amount }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      urlId: '',
      itemStorage: []
    }
  },
  methods: {
    async loadJsonStorage () {
      await this.resource.get()
        .then(response => response.json())
        .then(response => {
          this.itemStorage = response
        })
      this.loading = false
    }
  },
  mounted () {
    this.loadJsonStorage()
  },
  created () {
    if (localStorage.getItem('activeItem') === '1') {
      this.resource = this.$resource('products/ua/' + this.$route.params['id'] + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
    } else {
      this.resource = this.$resource('products/' + this.$route.params['id'] + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
    }
  },
  watch: {
    $route (to, from) {
      this.urlId = to.params['id']
    }
  }
}
</script>
