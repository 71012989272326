<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a
          v-bind:href="
            '#/CatalogDetail/' +
            this.$route.params['idCategory'] +
            '/' +
            this.$route.params['idProduct']
          "
          class="link-back"
        >
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{
            $ml.with("VueJS").get("techRequest")
          }}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="form-holder">
        <!--        <form @submit.prevent="$modal.show('send-request-equipment')" id="order-form">-->
        <form @submit.prevent="sendApiForm()" id="order-form">
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userName.$error }"
          >
            <input
              id="user_name"
              name="user_name"
              type="text"
              v-model="userName"
              @input="$v.userName.$touch()"
              @blur="$v.userName.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userName.$model }"
            />
            <label for="user_name" class="label">{{
              $ml.with("VueJS").get("labelContactPerson")
            }}</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userName.$error"
            ></span>
            <div class="error" v-if="$v.userName.$error">
              {{ $ml.with("VueJS").get("setContactPerson") }}
            </div>
          </div>
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userPhone.$error }"
          >
            <the-mask
              mask="+38 (0##) ###-##-##"
              id="user_phone"
              name="user_phone"
              type="tel"
              v-model="userPhone"
              @input="$v.userPhone.$touch()"
              @blur.native="$v.userPhone.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userPhone.$model }"
            />
            <label for="user_phone" class="label">{{
              $ml.with("VueJS").get("labelPhone")
            }}</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userPhone.$error"
            ></span>
            <div class="error" v-if="$v.userPhone.$error">
              {{ $ml.with("VueJS").get("setPhone") }}
            </div>
          </div>
          <div
            class="form-group"
            v-bind:class="{ 'form-group-error': $v.userRegion.$error }"
          >
            <input
              id="user_region"
              name="user_region"
              type="text"
              v-model="userRegion"
              @input="$v.userRegion.$touch()"
              @blur="$v.userRegion.$touch()"
              class="input"
              v-bind:class="{ 'has-value': $v.userRegion.$model }"
            />
            <label for="user_region" class="label">{{
              $ml.with("VueJS").get("labelRegion")
            }}</label>
            <span
              class="icon icon-error"
              aria-label="icon error"
              v-if="$v.userRegion.$error"
            ></span>
            <div class="error" v-if="$v.userRegion.$error">
              {{ $ml.with("VueJS").get("setRegion") }}
            </div>
          </div>
        </form>
      </div>
    </div>
    <!--    <Modal />-->
    <ModalSuccess />
    <div class="section-bottom">
      <button
        type="submit"
        form="order-form"
        class="btn btn-full-width btn-main"
      >
        {{ $ml.with("VueJS").get("send") }}
      </button>
    </div>

    <!--    <modal name="send-request-equipment" adaptive="true" resizable="true" height="auto">-->
    <!--      <h3>Отправить заявку на покупку трактора?</h3>-->
    <!--      <div class="modal-buttons">-->
    <!--        <button @click="$modal.hide('send-request-equipment')" class="btn btn-main asdasd">Отправить</button>-->
    <!--        <button @click="$modal.hide('send-request-equipment')" class="btn btn-link">Отменить</button>-->
    <!--      </div>-->
    <!--    </modal>-->
  </div>
</template>

<script>
import { required, helpers } from "vuelidate/dist/validators.min";
// import ModalSendRequestEquipment from '../modal/ModalSendRequestEquipment'
import ModalSuccess from "../modal/ModalSuccessForm";
import { TheMask } from "vue-the-mask";
import { GET_PRODUCTS, PRODUCT_ORDERS } from "../../helpers/requests";
const phone = helpers.regex("phone", /^[0-9]{9}$/);

export default {
  components: {
    TheMask,
    // Modal: ModalSendRequestEquipment,
    ModalSuccess: ModalSuccess,
  },
  data() {
    return {
      title: "Заявка на технику",
      userName: null,
      userFarm: null,
      userPhone: null,
      userRegion: null,
      productInfo: null,
      submitStatus: null,
      category: {},
    };
  },
  validations: {
    userName: {
      required,
    },
    userRegion: {
      required,
    },
    userPhone: {
      required,
      phone,
    },
  },
  mounted() {
    this.saveLocalStorage();
    this.loadJsonCategories();
  },
  watch: {},
  methods: {
    async sendApiForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        window.scrollTo(0, 0);
      } else {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();

        var myHeadersQ = new Headers();
        var requestOptionsQ = {
          method: "GET",
          headers: myHeadersQ,
          redirect: "follow",
        };
        await GET_PRODUCTS(
          this.$route.params["idProduct"] +
            "&token=" +
            localStorage.userToken +
            "&id=" +
            localStorage.userId,
          requestOptionsQ
        )
          .then((response) => response.text())
          .then(async (response) => {
            this.productInfo = response;

            urlencoded.append("firstname", this.userName);
            if (this.userFarm) {
              urlencoded.append("farm", this.userFarm);
            }
            urlencoded.append("phone", "0" + this.userPhone);
            urlencoded.append("product", JSON.parse(this.productInfo).name);
            urlencoded.append("region", this.userRegion);

            // eslint-disable-next-line no-redeclare
            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: urlencoded,
              redirect: "follow",
            };
            await PRODUCT_ORDERS(requestOptions)
              .then((response) => response.text())
              .then((result) => {
                console.log(result);
                this.$modal.show("success-request-equipment");
              })
              .catch((error) => console.log("error", error));
          })
          .catch((error) => console.log("error", error));
      }
    },
    loadJsonCategories() {
      this.categoriesJson
        .get()
        .then((response) => response.json())
        .then((response) => {
          this.category = response;
        });
    },
    saveLocalStorage() {
      // console.log(localStorage.userName)
      // console.log(localStorage.userToken)

      if (localStorage.userName && localStorage.userToken) {
        this.userName = localStorage.userName;
      }

      if (localStorage.userCompany && localStorage.userToken) {
        this.userFarm = localStorage.userCompany;
      }

      // if (localStorage.userEmail) {
      //   this.userEmail = localStorage.userEmail
      // }
      if (localStorage.userPhone && localStorage.userToken) {
        this.userPhone = localStorage.userPhone;
      }
    },
    submitRequestEquipment() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // this.$modal.show('send-request-equipment')
        this.$modal.show("success-request-equipment");
        this.$v.$reset();
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
    someAction() {
      alert("Форма отправлена");
    },
  },
  created() {
    this.categoriesJson = this.$resource(
      "categories/" + this.$route.params["idCategory"]
    );
    this.resource = this.$resource("product_orders/");
  },
};
</script>
