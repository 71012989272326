<template>
  <div>
    <h1>{{name}}</h1>
    <img alt="Vue logo" src="../assets/logo.png">
    <p>Side Side Side Side Side Side Side</p>
    <p>{{categories.id}}</p>
    <div class="section-top">
      <div class="title-holder">
        <div v-for="items in categories" :key="items.id">
          <a v-if="items.id === id"
             v-bind:href="'#/Catalog/' + items.parent" class="link-back">
            <i class="icon icon-arrow-back"></i>
          </a>
          <a class="link-back">
            <i class="icon icon-arrow-back"></i>
          </a>
        </div>
        <h1 class="title">
          <span class="title-item">
              <i class="icon icon-tractor"></i>
            <div v-for="cat in categories" :key="cat.id">
              <div v-if="cat.id === id">{{cat.name}}</div>
            </div>
          </span>
        </h1>
      </div>
    </div>
    <div class="section-main catalog-main-section">
      <nav class="catalog-list">
        <div v-for="items of categories"
             :key="items.id"
        >
          <a>
          <span class="catalog-image">
            <img v-bind:src="items.icon" v-bind:alt="items.name"/>
          </span>
            <span class="catalog-item-name">{{items.name}}</span>
          </a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      name: 'Side',
      id: this.$route.params['id'],
      categories: Array
    }
  },
  methods: {
    loadJsonCategories () {
      this.categoriesJson.get().then(response => response.json()).then(response => (this.categories = response))
    }
    // clickSub () {
    //   for (var categ of this.categories) {
    //     // console.log(categ)
    //   }
    // }
  },
  mounted () {
    this.clickSub()
    // console.log(this.categories)
    this.loadJsonCategories()
  },
  created () {
    this.categoriesJson = this.$resource('categories/')
  }
}
</script>
