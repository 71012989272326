<template>
  <div>
    <div class="page-container catalog-detail"
         v-bind:class="{activePointDetail: pointDetail}"
    >
        <!--<pre>{{info}}</pre>-->
        <div class="section-top">
            <div class="title-holder">
                <a v-bind:href="'#/CatalogSection/' + this.$route.params['idCategory']" class="link-back"
                   v-if="!pointDetail">
                    <i class="icon icon-arrow-back"></i>
                </a>
                <span class="link-back"
                      v-if="pointDetail"
                      @click="pointDetail=false, activeIndex = null"
                >
          <i class="icon icon-arrow-back"></i>
        </span>
            </div>
        </div>
      <Loader v-if="loading"/>
        <div v-else class="section-main">
            <div class="detail-image">
                <div class="detail-image-frame">

                    <img v-bind:src="info.preview_img" v-bind:alt="info.name">
                    <!--          <div class="detail-points">-->
                    <!--            <span class="detail-point"-->
                    <!--                  v-for="(item, itemIndex) in pointCoordinates"-->
                    <!--                  v-bind:style="{ top: item.top, left: item.left}"-->
                    <!--                  @click="selectedPoint(item, itemIndex)"-->
                    <!--                  :class="{'active': activeIndex === itemIndex}"-->
                    <!--                  :key="itemIndex"-->
                    <!--            ></span>-->
                    <!--          </div>-->
                </div>
            </div>
            <div class="detail-info"
                 v-if="!pointDetail"
            >
                <div class="detail-title">{{info.name}}</div>
                <div class="detail-info__container">
                    <div class="detail-info__item" v-for="(item, ind) in info.property" :key="item.id" :class="{'change-info-box': ind === 0}">
                        <div v-for="(value, name) in item" :key="value.id">
                          <div class="detail-info__title">{{ name }}</div>
                          <div v-if="(value.length > 1) && ind === 0" class="detail-info__value">
                            <div class="detail-info__parameters">
                              <div class="detail-info__parameter"
                                v-for="(item, itemIndex) in value" :key="item.id"
                                :class="{'active': activeItemId === itemIndex}"
                                @click="setParameterActive(itemIndex)">
                                  {{item}}
                              </div>
                            </div>
                          </div>
                          <template v-else>
                            <template v-for="(item, index) in value">
                              <template v-if="value.length>1">
                                <div
                                  v-if="activeItemId === index"
                                  :key="item.id"
                                  class="detail-info__value"
                                >{{item}}</div>
                              </template>
                              <div
                                v-else
                                :key="item.id"
                                class="detail-info__value"
                              >{{item}}</div>
                            </template>
                          </template>
                        </div>
                    </div>
                    <!--          <template v-for="(item, index) in workingWidth" v-if="activeItemId === index">-->
                    <!--            <div class="detail-info__item" v-for="itemInner in item.parameter">-->
                    <!--              <div class="detail-info__title">{{itemInner.title}}</div>-->
                    <!--              <div class="detail-info__value">{{itemInner.value}}</div>-->
                    <!--            </div>-->
                    <!--          </template>-->
                </div>
            </div>
          <div class="detail-info__container">
            <div class="detail-description">
              <div class="detail-description-text" v-html="decodeHtml(description)"></div>
            </div>
          </div>
        </div>
        <Modal v-bind:url="'#/NewRequestEquipment/' + this.idCategory + '/' + info.id"/>
        <div class="section-bottom"
             v-if="!pointDetail"
        >
          <a v-on:click="submitRequestEquipment()"
               class="btn btn-full-width btn-main">{{$ml.with('VueJS').get('SendRequest')}}</a>
        </div>
    </div>
  </div>
</template>

<script>
import ModalSendRequestEquipment from '../modal/ModalSendRequestEquipment'

export default {
  components: {
    Modal: ModalSendRequestEquipment
  },
  data () {
    return {
      info: Object,
      loading: true,
      errored: false,
      getId: '',
      idCategory: this.$route.params['idCategory'],
      description: '',
      activeItemId: 0,
      activeIndex: undefined,
      pointDetail: false
    }
  },
  computed: {
  },
  methods: {
    decodeHtml: function (html) {
      var txt = document.createElement('textarea')
      txt.innerHTML = html
      return txt.value
    },
    async loadProducts2 () {
      await this.resource.get().then(response => response.json())
        .then(response => {
          this.info = response
        })
      await this.resource.get().then(response => response.json())
        .then(response => {
          // console.log(response.property[0][Object.keys(response.property[0])[0]])

          for (var vv in response.property[0][Object.keys(response.property[0])[0]]) {
            // console.log(response.property[0][Object.keys(response.property[0])[0]][0])
            response.property[0][Object.keys(response.property[0])[0]][vv] = response.property[0][Object.keys(response.property[0])[0]][vv].replace('\\n', '\n')
            response.property[0][Object.keys(response.property[0])[0]][vv] = response.property[0][Object.keys(response.property[0])[0]][vv].replace('/n', '\n')
            response.property[0][Object.keys(response.property[0])[0]][vv] = response.property[0][Object.keys(response.property[0])[0]][vv].replace('<br>', '\n')
          }
          this.info.property = response.property
          this.description = response.description.replace(/<\/?[^&gt;]+>/g, '')
          // this.description = response.description.replace(/[^A-Za-zА-Яа-яЁё]/g, '')
          // document.querySelector('.detail-description-text').innerHTML(response.description)
          // document.querySelector('.detail-description-text').innerHTML = namesss
          // console.log(document.querySelector('.detail-description-text').innerHTML = namesss)
        })
      this.loading = false
    },
    setParameterActive (itemIndex) {
      this.activeItemId = itemIndex
    },
    selectedPoint (index, itemIndex) {
      if (this.activeIndex === itemIndex) {
        this.activeIndex = null
        this.pointDetail = false
      } else {
        this.activeIndex = itemIndex
        this.pointDetail = true
      }
    },
    submitRequestEquipment () {
      this.$modal.show('send-request-equipment')
    }
  },
  mounted () {
    // console.log(this.$el.querySelector('.detail-description-text'))
    this.loadProducts2()
  },
  created () {
    if (localStorage.getItem('activeItem') === '1') {
      this.resource = this.$resource('products/ua/' + this.$route.params['idProduct'] + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
    } else {
      this.resource = this.$resource('products/' + this.$route.params['idProduct'] + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
    }
  }
}
</script>

<style>
  div.detail-description {
    height: auto;
    overflow-y: hidden;
    margin-bottom: -30px;
  }
</style>
