<template>
  <div>
    <template v-if="visible">
      <div class="user-info-holder">
        <div class="user-info">
          <div class="row">
            <span class="sub-title">{{
              $ml.with("VueJS").get("labelContactPerson")
            }}</span>
          </div>
          <div class="row">
            <span class="text">{{ defaultUserName }}</span>
          </div>
          <div class="row">
            <span class="text">{{ defaultUserEmail }}</span>
          </div>
          <div class="row">
            <span class="text"
              >+38
              {{
                defaultUserPhone
                  ?.slice(2)
                  .replace(this.regPhone, "($1) $2-$3-$4")
              }}</span
            >
          </div>
          <!-- <span class="link-red" @click="visible = !visible">{{
            $ml.with("VueJS").get("changeContactPerson")
          }}</span> -->
        </div>
      </div>
    </template>
    <template v-else>
      <div class="user-info-holder">
        <div class="user-info">
          <div class="row">
            <span class="sub-title">{{
              $ml.with("VueJS").get("labelContactPerson")
            }}</span>
          </div>
          <form @submit.prevent="submitUserForm()" class="form-grey-bg">
            <div
              class="form-group"
              v-bind:class="{ 'form-group-error': $v.userName.$error }"
            >
              <input
                id="user_name"
                name="user_name"
                type="text"
                v-model="userName"
                @input="$v.userName.$touch()"
                @blur="$v.userName.$touch()"
                class="input"
                v-bind:class="{ 'has-value': $v.userName.$model }"
              />
              <label for="user_name" class="label"
                >{{ $ml.with("VueJS").get("name") }}
                {{ $ml.with("VueJS").get("surname") }}</label
              >
              <span
                class="icon icon-error"
                aria-label="icon error"
                v-if="$v.userName.$error"
              ></span>
              <template v-if="$v.userName.$error">
                <div class="error" v-if="!$v.userName.required">
                  {{ $ml.with("VueJS").get("setNameSurname") }}
                </div>
                <div class="error" v-if="!$v.userName.alpha">
                  {{ $ml.with("VueJS").get("setNameSurnameError") }}
                </div>
              </template>
            </div>

            <div
              class="form-group"
              v-bind:class="{ 'form-group-error': $v.userEmail.$error }"
            >
              <input
                id="user_email"
                name="user_email"
                type="text"
                v-model="userEmail"
                @input="$v.userEmail.$touch()"
                @blur="$v.userEmail.$touch()"
                class="input"
                v-bind:class="{ 'has-value': $v.userEmail.$model }"
              />
              <label for="user_email" class="label">E-mail</label>
              <span
                class="icon icon-error"
                aria-label="icon error"
                v-if="$v.userEmail.$error"
              ></span>
              <div class="error" v-if="$v.userEmail.$error">
                {{ $ml.with("VueJS").get("setEmail") }}
              </div>
            </div>

            <div
              class="form-group"
              v-bind:class="{ 'form-group-error': $v.userPhone.$error }"
            >
              <the-mask
                mask="+38 (0##) ###-##-##"
                id="user_phone"
                name="user_phone"
                type="tel"
                v-model="userPhone"
                @blur.native="$v.userPhone.$touch()"
                @input="$v.userPhone.$touch()"
                class="input"
                v-bind:class="{ 'has-value': $v.userPhone.$model }"
              />
              <label for="user_phone" class="label">Телефон</label>
              <span
                class="icon icon-error"
                aria-label="icon error"
                v-if="$v.userPhone.$error"
              ></span>

              <div class="error" v-if="$v.userPhone.$error">
                {{ $ml.with("VueJS").get("setPhoneNum") }}
              </div>
            </div>

            <div class="btn-holder">
              <button type="submit" class="btn btn-link-red">
                {{ $ml.with("VueJS").get("save") }}
              </button>
              <span class="link-grey" @click="visible = !visible">{{
                $ml.with("VueJS").get("сancel")
              }}</span>
            </div>
          </form>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { required, email, helpers } from "vuelidate/dist/validators.min";
import { TheMask } from "vue-the-mask";
import { GET_USERS, LOG_LOGOUTS } from "../../helpers/requests";
const phone = helpers.regex("phone", /^[0-9]{9}$/);
const alpha = helpers.regex("alpha", /^[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ' ]+$/);

export default {
  name: "FormComponent",
  components: {
    TheMask,
  },
  data() {
    return {
      defaultUserName: localStorage.contactName,
      defaultUserEmail: localStorage.contactEmail,
      defaultUserPhone: localStorage.contactPhone,
      userPhone: null,
      userEmail: null,
      userName: null,
      visible: true,
      regPhone: /(\d{3})(\d{3})(\d{2})(\d{2})/,
    };
  },
  watch: {
    // userName (newName) {
    //   localStorage.contactName = newName
    // },
    // userEmail (newEmail) {
    //   localStorage.contactEmail = newEmail
    // },
    // userPhone (newPhone) {
    //   localStorage.contactPhone = newPhone
    // },
    defaultName(newName) {
      // localStorage.contactName = newName
    },
    defaultEmail(newEmail) {
      // localStorage.contactEmail = newEmail
    },
    defaultPhone(newPhone) {
      // localStorage.contactPhone = newPhone
    },
  },
  validations: {
    userName: {
      required,
      alpha,
    },
    userEmail: {
      required,
      email,
    },
    userPhone: {
      required,
      phone,
    },
  },
  mounted() {
    this.saveLocalStorage();
    this.defaultValues();
  },
  // filters: {
  //   phoneFormat (value) {
  //     return value.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, '($1) $2-$3-$4')
  //   }
  // },
  methods: {
    async saveLocalStorage() {
      // if (localStorage.userName) {
      //   this.userName = localStorage.userName
      // }
      // if (localStorage.userEmail) {
      //   this.userEmail = localStorage.userEmail
      // }
      // console.log(localStorage)
      // if (localStorage.userPhone) {
      //   this.userPhone = localStorage.userPhone
      // }
      if (
        !localStorage.userPhone ||
        !localStorage.userName ||
        !localStorage.userEmail
      ) {
        this.users = await GET_USERS(localStorage.userId)
          .then((response) => response.json())
          .then((response) => {
            if (
              typeof response.can_use_app === "boolean" &&
              !response.can_use_app
            ) {
              this.sendLogoutLogs({
                data: `Користувач ${localStorage.userName} з id ${localStorage.userId} не має доступу до додатку (UserInfo.234)`,
              });
              localStorage.clear();
              return;
            }
            if (!this.defaultUserPhone) {
              this.defaultUserPhone = response.phone;
            }
            if (!this.defaultUserName) {
              this.defaultUserName = response.name;
            }
            if (!this.defaultUserEmail) {
              this.defaultUserEmail = response.email;
            }
            // this.defaultUserPhone = response.phone
            // this.defaultUserName = response.name
            // this.defaultUserEmail = response.email
            this.userName = this.defaultUserName;
            this.userEmail = this.defaultUserEmail;
            this.userPhone = this.defaultUserPhone;
            // localStorage.setItem('userName', response.name)
            // localStorage.setItem('userPhone', response.phone)
          });
      }
    },
    async sendLogoutLogs(errorMessage) {
      try {
        await LOG_LOGOUTS(JSON.stringify(errorMessage));
      } catch (error) {
        console.log(error.message);
      }
    },
    defaultValues() {
      if (localStorage.contactName) {
        this.defaultUserName = localStorage.contactName;
      } else {
        this.defaultUserName = localStorage.userName;
      }
      if (localStorage.contactEmail) {
        this.defaultUserEmail = localStorage.contactEmail;
      } else {
        this.defaultUserEmail = localStorage.userEmail;
      }
      if (localStorage.contactPhone) {
        this.defaultUserPhone = localStorage.contactPhone;
      } else {
        this.defaultUserPhone = localStorage.userPhone;
      }
    },
    submitUserForm() {
      console.log("submit btn user-form");
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
          this.defaultUserPhone = "0" + this.userPhone;
          this.defaultUserEmail = this.userEmail;
          this.defaultUserName = this.userName;
          this.visible = !this.visible;
          // console.log(localStorage)
          localStorage.setItem("responsibleName", this.userName);
          localStorage.setItem("responsibleEmail", this.userEmail);
          localStorage.setItem("responsiblePhone", "0" + this.userPhone);
        }, 500);
      }
    },
  },
};
</script>

<style>
.btn-holder .btn.btn-link-red {
  margin-right: 20px;
}
</style>
