<template>
  <div class="page-container">
    <div class="section-top">
      <div class="title-holder">
        <a href="#/Teaching" class="link-back">
          <i class="icon icon-arrow-back"></i>
        </a>
        <h1 class="title">
          <span class="title-item">{{$ml.with('VueJS').get('myApp')}}</span>
        </h1>
      </div>
    </div>
    <div class="section-main">
      <div class="request-items">
        <template v-for="request in requests">
          <a :key="request.id" :href="'#/TrainingRequestDetail/' + request.education_id" class="request-item">
            <span class="request-item-top">
              <span class="request-item-num">№{{request.education_id}} {{request.created_at}}</span>
              <span class="request-status status-new">{{request.statuss}}</span>
            </span>
            <span class="request-item-text tt-n">{{request.theme}}</span>
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
module.exports = {
  data: function () {
    return {
      requests: ''
      // requests: [
      //   {
      //     id: '#/TrainingRequestDetail',
      //     title: 'Тракторы Case IH Magnum',
      //     num: '№2',
      //     text: 'от',
      //     date: '01.04.2019',
      //     status: 'Новая',
      //     statusClass: 'status-new'
      //   },
      //   {
      //     id: '#/TrainingRequestDetail',
      //     title: 'Комбайны Case IH',
      //     num: '№1',
      //     text: 'от',
      //     date: '06.04.2019',
      //     status: 'Закрыта',
      //     statusClass: 'status-canceled'
      //   }
      // ]
    }
  },
  mounted () {
    // this.sendPost()
    this.loadJsonCategories()
  },
  methods: {
    loadJsonCategories () {
      this.categoriesJson.get().then(response => response.json())
        .then(response => {
          for (var item in response) {
            let date = ''
            let monthstr = ''
            if (response[item].created_at) {
              date = new Date(response[item].created_at)

              let month = parseInt(date.getMonth() + 1)
              if (month <= 9) {
                monthstr = '0' + month
              } else {
                monthstr = '' + month
              }
              response[item].created_at = 'від ' + date.getDate() + '.' + monthstr + '.' + date.getFullYear()
              // console.log(date.getDate() + '.' + monthstr + '.' + date.getFullYear())
            }
            response[item].statuss = 'В роботі'
          }
          response.sort(function (obj1, obj2) {
            return obj2.education_id - obj1.education_id
          })
          this.requests = response
        })
    }
    // sendPost () {
    //   var myHeaders = new Headers()
    //   myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
    //
    //   var urlencoded = new URLSearchParams()
    //
    //   var requestOptions = {
    //     method: 'GET',
    //     headers: myHeaders,
    //     body: urlencoded,
    //     redirect: 'follow'
    //   }
    //
    //   fetch('/edu/320', requestOptions)
    //     .then(response => response.text())
    //     .then(result => console.log(result))
    //     .catch(error => console.log('error', error))
    // }
  },
  created () {
    this.categoriesJson = this.$resource('edu/' + localStorage.userId)
  }
}
</script>
